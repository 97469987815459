import React, { useState, useCallback } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../../../helpers/CanvasUtils';



const ProfilePhoto = ({username}) => {
    const navigate = useNavigate()
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [defaultPhoto, setDefaultPhoto] = useState(null);
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [aspect, setAspect] = useState(1)
    const [croppedArea, setCroppedArea] = useState("");
    const [croppedImage, setCroppedImage] = useState(null)
    

    const handleUpdate = async (e) => {
        const token = localStorage.getItem('token');
        e.preventDefault();
        try {
          const croppedImage = await getCroppedImg(profilePhoto, croppedArea, 0);
          let file = new File([croppedImage], defaultPhoto.name, {type: defaultPhoto.type})
          setCroppedImage(file);
 

        console.log(croppedImage)
        console.log(defaultPhoto)
        const formData = new FormData();
        if (croppedImage !== null) {
          formData.append('image', croppedImage );
        }else{
          formData.append('image', defaultPhoto);
        }
        formData.append('name', username);
        try {
          await axios.post('https://api.fancareapp.com/user/update/profilePicture/' + username,
            formData,
            { headers: {'Content-Type': 'multipart/form-data',
          'Authorization': token }, 
        }
          );  
          navigate(0)
        } catch (error) {
          console.log('Error updating profile photo :', error);
        }
      } catch (error) {
        console.error(error);
    }


      
      };
  


    const handleProfilePhotoUpload = async (event) => {
      const file = event.target.files[0];
      // Perform any necessary validation or resizing of the uploaded file
      // Then set the profile photo state
      let imageDataUrl = await readFile(file);
      setDefaultPhoto(file);
      setProfilePhoto(imageDataUrl);
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    }

    const onZoomChange = (zoom) => {
        setZoom(zoom);
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }, []);
    



  return (
    <div>
   <input className="w-full file:bg-colorPrimary file:border-none my-6 file:text-colorLight file:rounded file:px-5 file:py-2.5 file:transition file:duration-150 file:ease-in-out hover:file:bg-colorBg hover:file:text-colorPrimary" type="file" accept="image/*" onChange={handleProfilePhotoUpload} />
      {profilePhoto && (
        <div>
          <br />
          <h3>Preview:</h3>
          <div className='relative h-20vh'>
          <Cropper
            image={profilePhoto} 
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            cropShape="round"
            showGrid={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
          </div>

          <input type="range" min="1" max="3" step="0.1" defaultValue="1" className="py-8 w-full" onChange={(e) => onZoomChange(e.target.value)} />
          
        </div>
      )}
      {/* Other input fields for updating details */}
      {defaultPhoto && <button onClick={handleUpdate}>Save Changes</button>}



    </div>
  )
}

function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }



export default ProfilePhoto