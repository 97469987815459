import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet-async';


import Leaderboard from "../Leaderboard/Leaderboard"
import donate from '../../img/donate.png'
import wishlist from '../../img/wishlist.png'
import FAQ from '../../components/FAQ/Faq'

const Landing = ({currency, currencyData}) => {
    const [username, setUsername] = useState("")

  return (
    <>
      <Helmet>
        <title>Fancare App | Privacy-first Wishlist for Influencers and Content Creators</title>
        <meta name="description" content="A privacy-first wishlist platform specially created for infuencers and content creators. Create an account now and start receiving gifts from your fans!"/>
        <link rel="canonical" href="/"/>
      </Helmet>
  <section className= "flex flex-col items-center max-w-screen-xl px-4 py-16 mx-auto lg:pt-40 lg:pb-20 ">

      <div className='text-center sm:w-50vw'>
        <h1 className='text-6xl text-colorDarkGray font-bold'>The <u className='decoration-colorSecondary'>Ultimate</u>  Platform for Creator Support.</h1> 

        <h2 className='text-xl pt-8 pb-16 text-colorLightGray font-medium'>Receive donations and gifts. Create your perfect wishlist. Give back to your community. </h2>
        
        <div className='flex bg-white text-colorDarkGray sm:w-fit py-4 pl-8 pr-4 text-xl sm:m-auto rounded-full items-center'>
          <span className=' font-bold'>fancare.app/</span>
          <input type="text" className="text-colorDark w-auto lg:w-10vw focus:outline-none " placeholder={"username"} onChange={(e) => setUsername(e.target.value)}/>
        <Link to={`/signup/${username}`}><button className='hidden md:block bg-colorPrimary   w-auto lg:w-10vw p-2 text-white rounded-full' >Get Started</button>
        </Link>
        </div>

        <Link to={`/signup/${username}`}><button className='block md:hidden bg-colorPrimary    w-full lg:w-10vw p-2 text-white rounded-full' >Get Started</button>
        </Link>


      </div>
      <nav className='flex mt-24 text-xl text-colorDarkGray font-bold gap-8 flex-col sm:flex-row text-center'>
        <div className='hover:text-colorPrimaryDark'>
          <a href="#features">Features</a>
        </div>
        <div className='hover:text-colorPrimaryDark'>
          <a href="#how-it-works">Getting Started</a>
        </div>
        <div className='hover:text-colorPrimaryDark'>
          <a href="#faq">FAQ</a>
        </div>
        <div className='hover:text-colorPrimaryDark'>
          <a href="#leaderboards">Leaderboards</a>
        </div>

      </nav>

  </section>

  <div className='hidden '>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><path fill="#7477ff" d="M251.57 256.95c-26.39-64.34-71.19-120.39-127.85-160.65 7.34-14.93 15-29.7 22.98-44.29.69-1.26.41-2.47-.32-3.31h.1c3.86.11 3.86-5.89 0-6-7.03-.2-14.05-.12-21.08.27-.11 0-.21.03-.32.05-27.61-.44-55.12 3.13-81.71 10.6-2.27.64-2.8 3.35-1.32 5.01 21.6 24.44 40.7 50.92 57.13 79.09 1.04 1.78 4.24 2.12 5.18 0 5.35-12.07 10.91-24.04 16.67-35.91 38.95 27.8 72.39 63.26 97.72 103.98 10.45 16.8 19.52 34.46 27.03 52.77 1.45 3.52 7.25 1.98 5.79-1.6zM118.75 92.83s-.1-.07-.15-.1c-.25.6-.61 1.15-1.03 1.58-.1.54-.26 1.07-.53 1.55-.17.31-.37.59-.54.89-.26.47-.59.87-.98 1.2l.54.36a1144.96 1144.96 0 00-14.65 31.38 507.053 507.053 0 00-51.84-71.56c25.28-6.64 51.27-9.7 77.43-9.11.44 0 .82-.07 1.16-.2 4.53-.2 9.06-.28 13.6-.23-.09.12-.18.23-.25.37-7.91 14.45-15.5 29.08-22.77 43.85z"></path><path fill="none" stroke="#7477ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M140.3 51.74s.22 1.14.2 1.14c-.09.02-5.3 2.3-5.41 2.32-.17.03 1.72 9.03 1.6 9.06-.24.04-7.74-12.18-7.89-12.16-.26.05 4.49 24.16 4.27 24.2-.32.06-9.71-21.24-10.04-21.18-.35.06 5.19 27.71 4.9 27.77-.43.08-9.85-24.09-10.31-24.01-.51.09 7.1 36.83 6.51 36.94-.68.12-12.18-36.5-12.83-36.38s7.97 41.49 7.34 41.6c-.83.15-12.58-43.39-13.36-43.25-.59.1 9.64 50.64 8.95 50.76-.8.14-14.2-48.44-14.88-48.32-.93.16 11.44 60.18 10.64 60.32-.71.12-16.05-60.37-16.99-60.2-.82.14 13.61 69.25 12.25 69.49-1.01.18-17.85-72.09-18.61-71.95-1.08.19 14.76 75.48 13.35 75.73S81.65 56.93 80.87 57.07c-.71.12 11.53 59.41 10.51 59.59-.82.14-15.09-59.77-16.16-59.58-.6.11 10.8 56.76 10.03 56.89-.93.16-14.12-52.82-15.01-52.66-.63.11 8.59 44.99 7.95 45.1-.89.16-13.26-43.12-13.96-42.99-.49.09 4.6 23.56 4.17 23.64-.5.09-9.46-23.52-9.92-23.44-.31.05 3.35 16.96 3 17.02s-8.92-19.85-9.26-19.79c-.14.03 1.56 8.05 1.42 8.08"></path></svg>
      </div>


  <section id="features" className="w-screen-xl flex flex-col items-center  px-4 py-16  lg:py-40 bg-colorPrimaryDark text-colorBg">

      <div className='text-center sm:w-50vw'>
        <h2 className='text-2xl uppercase font-medium pb-8 text-colorDarkGray'>Wishlist</h2>

        

        <h3 className='text-6xl  font-bold '>Receive Cash for Your Gifts</h3>

        <div className='flex justify-center -mt-8 pb-8'>
          <svg xmlns="http://www.w3.org/2000/svg" className='w-20' viewBox="0 0 300 300"><path fill="#f59e0b" d="M78.3 189.56L20.88 141.5 40.14 118.5 78.3 150.44 126.1 110.44 173.89 150.44 221.69 110.44 279.12 158.5 259.86 181.5 221.69 149.56 173.89 189.56 126.1 149.56 78.3 189.56z"></path></svg>
        </div>

        <p className='opacity-70 text-lg'>Add gifts to your wishlist. Your Fans will pay a small fee to support the platform and for Stripe processing, and you will receive <b>exactly</b> how much you listed the gift for. <i>(Except in the case of currency conversions) </i></p>

        <div className='flex items-center justify-center m-0 md:m-16  p-16'>

          <img src={wishlist} alt="" className=' w-full  rounded-3xl'/>

        </div>
      </div>

      <div className='text-center sm:w-50vw'>
        <h2 className='text-2xl uppercase pb-8 font-medium text-colorDarkGray '>Donations</h2>
        <h3 className='text-6xl  font-bold'>A simple way to receive support from your Fans.</h3>

        <div className='flex justify-center -mt-8 pb-8'>
          <svg xmlns="http://www.w3.org/2000/svg" className='w-20' viewBox="0 0 300 300"><path fill="#f59e0b" d="M78.3 189.56L20.88 141.5 40.14 118.5 78.3 150.44 126.1 110.44 173.89 150.44 221.69 110.44 279.12 158.5 259.86 181.5 221.69 149.56 173.89 189.56 126.1 149.56 78.3 189.56z"></path></svg>
        </div>

        <p className='opacity-70 text-lg'>Receive donations from your fans with Fancare. By creating an account and completing our secure Stripe integration you will be ready to start receiving donation from your fans. </p>

        <div className='flex items-center justify-center m-0 md:m-16 p-16'>

         
          <img src={donate} alt="" className='w-full lg:w-80 rounded-3xl'/>


        </div>
      </div>        

  </section>

  <section id="how-it-works" className= "flex flex-col items-center max-w-screen-xl px-4 py-16 mx-auto lg:py-40 text-colorDarkGray">
    <div className=' sm:w-50vw'>

      <h1 className='text-center text-6xl  font-bold pb-16'>4 <u className='decoration-colorSecondary'>Simple</u> Steps.</h1>

      <div className= "grid max-w-screen-xl  mx-auto  lg:grid-cols-12">
      <div className='mr-auto place-self-center flex flex-col gap-10  lg:col-span-7 text-xl'>
        <div className='flex'>
          <span>1.</span>
          <p><b>Create a Fancare account and verify your email.</b></p>
        </div>  

        <div className='flex'>
          <span>2.</span>
          <p><b>Set up payments using Stripe via your Fancare Dashboard.</b> You can now receive donations!</p>
        </div>  

        
        <div className='flex'>
          <span>3.</span>
          <p><b>Add gifts to your wishlist.</b> This can be anything you like, simply set a price and add a description. </p>
        </div>  


       <div className='flex'>
          <span>4.</span>
          <p><b>Let your fans know about your Fancare account! </b>You will be notified when you receive a gift, and your cash will be deposited into your account.</p>
        </div>  


      </div>
 

      </div>

    </div>
  

  </section>

    

  <FAQ/>

  <section id="leaderboards" className="flex flex-col items-center max-w-screen-xl px-4 py-16 mx-auto lg:py-40 text-colorDarkGray">
     <h1 className='text-6xl  font-bold pb-16'>Join these Creators...</h1>
     <Leaderboard currency={currency} currencyData={currencyData}/>
  </section>



    </>
  )
}

export default Landing