import React, {useEffect, useState} from 'react'
import { useParams,  useNavigate } from 'react-router-dom'


const VerifyEmail = () => {
    const navigate = useNavigate()
    const { token } = useParams()
    const [errorMessage, setErrorMessage] = useState("You should be redirected soon.")

    useEffect(() => {
        fetch('https://api.fancareapp.com/verify/' + token, {
            method: 'GET',
        })
        .then(res => {
            if (res.ok){
                navigate('/dashboard')
            }else{
                setErrorMessage("Invalid token: Please contact Fancare support")
            }})

      },[token]);


  return (
    <div>
        <p>Verifying Email...</p>
        <p>{errorMessage}</p>
    </div>
  )
}

export default VerifyEmail