import React, {useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import { getUserData, isAuthenticated  } from '../../helpers/Auth'
import { FaPencilAlt} from "react-icons/fa"
import  {AiOutlineTrophy} from 'react-icons/ai'
import { MdOutlineLeaderboard } from 'react-icons/md'
import {FiRepeat, FiGift, FiPlusCircle} from 'react-icons/fi'
import { Helmet } from 'react-helmet-async'

import display from '../../img/defaultUser.png'
import banner from '../../img/defaultBanner.png'

import Modal from '../../components/Modal/Modal'
import Dropdown from '../../components/Dropdown/Dropdown'
import NewItem from './NewItem'
import Item from './Item'

import ProfilePhoto from './Update/ProfilePhoto'
import BannerPhoto from './Update/BannerPhoto'
import Donate from './Donate/Donate'
import Stats from './Stats/Stats'
import Achievements from './Achievements/Achievements'

const Wishlist = ({currency, currencyData}) => {
  const [wishlistItems, setWishlistItems] = useState([])
  const [user, setUser] = useState()
  const { username } = useParams()
  const [userData, setUserData] = useState()
  const [onboarded, setOnboarded] = useState(false)


  const [currentItem, setCurrentItem] = useState()
  const [showModalItem, setShowModalItem] = useState(false)
  const [showModalNew, setShowModalNew] = useState(false)
  const [showModalDonate, setShowModalDonate] = useState(false)
  const [showModalProfilePhoto, setShowModalProfilePhoto] = useState(false)
  const [showModalBannerPhoto, setShowModalBannerPhoto] = useState(false)
  const [tab, setTab] = useState("wishlist")
  const itemLimit = 30

  const [active, setActive] = useState("Date Added")
  const [sortCondition, setSortCondition] = useState()

  useEffect(() => {

    setUserData(getUserData())
  }, []);

  useEffect(() => {
    //Updates sort
     switch(active){
      case "Date Added":
        setSortCondition(() => (a, b) => (a.createdAt > b.createdAt) ? 1 : -1)
        break;

      case "Alphabetical":
        setSortCondition(() => (a, b) => (a.name > b.name) ? 1 : -1)
        break;

      case "Price Ascending":
        setSortCondition(() => (a, b) => (a.price > b.price) ? 1 : -1)
        break;

      case "Price Descending":
        setSortCondition(() => (a, b) => (a.price < b.price) ? 1 : -1)
        break;


     }
  },[active])


const isMyWishlist = () => {
  return userData?.username === user?.name
}


  useEffect(() => {

    fetch('https://api.fancareapp.com/user/' + username.toLowerCase())
    .then(res => res.json())
    .then(user => {
      if(user?.stripeId){

        fetch('https://api.fancareapp.com/check_onboarded/'+ user.name + '/' , {headers: {
          'Content-Type': 'application/json',
        }}).then(
          res => res.json())
          .then(onboarded => setOnboarded(onboarded))
      }else{
        setOnboarded(false)
      }
      setUser(user)})

  },[username ]);

  useEffect(() => {
    fetch('https://api.fancareapp.com/wishlist/items/' + user?.name)
    .then(res => res.json())
    .then(items => setWishlistItems(items))
  }, [user]);

  


  return (
    <>
    <Helmet>
      <title>{`${user?.displayName}'s Wishlist | Privacy-first Wishlist for Influencers and Content Creators`}</title>
      <meta name="description" content="A privacy-first wishlist platform specially created for infuencers and content creators. Create an account now and start receiving gifts from your fans!"/>
      <link rel="canonical" href="/"/>
    </Helmet>

  <div className="mx-auto min-h-screen">

        {isMyWishlist() && isAuthenticated() && user?.emailVerified ?
        <>
        <div className='brightness-90'>
        <img onClick={() => setShowModalBannerPhoto(true)} className="w-full h-30vh cursor-pointer hover:opacity-80 object-cover" src={user?.bannerPhotoUrl ? user?.bannerPhotoUrl : banner } alt="default wishlist background" />
        </div>
        { onboarded && wishlistItems?.length <= itemLimit ? <>
          <div className='md:px-10vw w-full bg-colorLightGray shadow py-4 px-16  flex  items-center justify-end'>

          <p className='text-lg text-colorLight md:block hidden px-8'>{wishlistItems?.length}/{itemLimit}</p>

          <button onClick={() => setShowModalNew(true)} className='md:flex items-center justify-center gap-x-1.5 rounded-md bg-colorLight px-3 py-2 text-colorDarkGray shadow-sm text-lg block hidden'><FiPlusCircle/>Add Gift</button>
          </div>

       </> : 

        <>
        <div className='md:px-10vw w-full bg-colorLightGray shadow py-8 px-16  flex  items-center justify-end'>

        </div>

       </>
        }
        
        </>
          :

          <>
          <div className='brightness-90'>

          <img  className="w-full h-30vh object-cover " src={user?.bannerPhotoUrl ? user?.bannerPhotoUrl : banner } alt="default wishlist background" />
          </div>

          <div className='md:px-10vw w-full bg-colorLightGray shadow py-8 px-16  flex  items-center justify-end'>

          </div>
         
          </>

          
          }
          <div className=' md:px-10vw  w-full bg-colorBg  px-8 py-4  items-center justify-start gap-4 hidden sm:flex'>
            <div className="lg:w-25vw md:w-30vw w-auto"> 
            </div>
            <button className={tab === 'wishlist' ? "flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-colorPrimaryDark  shadow-sm text-lg ": "flex items-center justify-center gap-x-1.5 rounded-md bg-colorLight px-3 py-2 text-colorDarkGray shadow-sm text-lg" } onClick={() => setTab("wishlist")}><FiGift/>Wishlist</button>

            <button className={tab === 'stats' ? "flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-colorPrimaryDark  shadow-sm text-lg ": "flex items-center justify-center gap-x-1.5 rounded-md bg-colorLight px-3 py-2 text-colorDarkGray shadow-sm text-lg" } onClick={() => setTab("stats")}><MdOutlineLeaderboard/>Stats</button>
           
            <button className={tab === 'achievements' ? "flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-colorPrimaryDark  shadow-sm text-lg ": "flex items-center justify-center gap-x-1.5 rounded-md bg-colorLight px-3 py-2 text-colorDarkGray shadow-sm text-lg" } onClick={() => setTab("achievements")}><AiOutlineTrophy/>Achievements</button>

          </div>

      <div className='md:px-10vw px-8 py-8  relative'>
        <section className='flex justify-start items-end '>
          <div className='-mt-30vh bg-white lg:w-25vw md:w-30vw w-full  rounded-lg shadow p-4 pb-8 flex flex-col items-center '>
            {isMyWishlist() && isAuthenticated() ? 
            <img onClick={() => setShowModalProfilePhoto(true)} className="-mt-20 rounded-full  h-20vh shadow mb-8 cursor-pointer hover:opacity-80" src={user?.profilePicture ? user?.profilePictureUrl : display} alt="Profile " />
              :
            <img className="-mt-20 rounded-full  h-20vh shadow mb-8 " src={user?.profilePicture ? user?.profilePictureUrl : display} alt="Profile " />

            }
            <div className='flex gap-2 flex-1  '>
            <h1 className="text-2xl font-bold break-all">{user?.name ? <>{user?.displayName ? <>{user?.displayName}</> : <>{user?.name}</>}'s Wishlist </> : <>There is nothing here...</>}</h1>
            {isMyWishlist() && isAuthenticated() &&
            <Link to='/dashboard/customize' className='hover:text-colorPrimary'>{<FaPencilAlt/>}</Link>
            } 
            </div>


            <h1 className="text-1xl font-bold text-colorDarkGray mb-4">@{user?.name?.toLowerCase()}</h1>
            <p>{user?.bio}</p>

            {!isMyWishlist() && user?.emailVerified && onboarded &&
            <button onClick={() => setShowModalDonate(true)} className=' bg-colorPrimary focus:outline-none font-medium px-6 py-2.5  mt-8 text-white rounded-full'>Donate!</button>
}

            {isMyWishlist() && isAuthenticated() && user && !user?.emailVerified &&  <Link to='/dashboard' ><h2 className='text-red-500 '>You must verify your email before adding items to your wishlist</h2></Link>
}
            {isMyWishlist() && isAuthenticated() && user && !onboarded && <Link to='/dashboard' ><h2 className='text-red-500 '>You must set up Stripe before adding items to your wishlist</h2></Link>}
          </div>



        </section>
            
            
        <div className='w-full bg-colorLightGray shadow rounded-b-lg py-4 px-4  flex  items-center justify-end md:hidden'>
        {isMyWishlist() && isAuthenticated() && onboarded && wishlistItems?.length <= itemLimit && <button onClick={() => setShowModalNew(true)}><p className='text-md text-colorLight '>Add Item</p></button>}
        </div>

        <div className=' md:px-10vw  w-full bg-colorBg  px-8 py-4   gap-4 sm:hidden flex flex-col'>
            <div className="lg:w-25vw md:w-30vw w-auto"> 
            </div>
            <button className={tab === 'wishlist' ? "flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-colorPrimaryDark  shadow-sm text-lg ": "flex items-center justify-center gap-x-1.5 rounded-md bg-colorLight px-3 py-2 text-colorDarkGray shadow-sm text-lg" } onClick={() => setTab("wishlist")}><FiGift/>Wishlist</button>

            <button className={tab === 'stats' ? "flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-colorPrimaryDark  shadow-sm text-lg ": "flex items-center justify-center gap-x-1.5 rounded-md bg-colorLight px-3 py-2 text-colorDarkGray shadow-sm text-lg" } onClick={() => setTab("stats")}><MdOutlineLeaderboard/>Stats</button>
           
            <button className={tab === 'achievements' ? "flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-colorPrimaryDark  shadow-sm text-lg ": "flex items-center justify-center gap-x-1.5 rounded-md bg-colorLight px-3 py-2 text-colorDarkGray shadow-sm text-lg" } onClick={() => setTab("achievements")}><AiOutlineTrophy/>Achievements</button>

          </div>
          

        {tab === "wishlist" && 
        <>
        <br />
        <div className='flex w-full justify-end '>            
          <Dropdown selected={active} setSelected={setActive}/>
        </div>
        <br />
        {!onboarded || wishlistItems?.length === 0 ? (
          <p className="">No items found in the wishlist.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">

            {wishlistItems.sort(sortCondition).map(item => (
              <div onClick={() => {
                setCurrentItem(item)
                setShowModalItem(true)}}
                key={item.id}
                className="bg-white rounded-lg shadow p-4 flex flex-col cursor-pointer"
              >
                <div className="mb-4">
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="w-full h-auto rounded-l"
                  />
                </div>
                <div className="flex-1 ">
                  <div className='flex gap-2 place-content-between items-center	 '>
                  <h2 className="text-lg text-colorGray font-bold">{item.name} </h2>
                  {item.repeating && <h2 className="text-lg text-colorGray font-bold"><FiRepeat/></h2>}
                  </div>
                  <p className="text-colorLightGray">{item.description}</p>
                  <p className="text-colorGray my-2 font-bold">{currency !== "USD" && <i>est.</i> } {currency} {(Math.round((item.price + Number.EPSILON)  * 1.1 *   currencyData[currency] *  100) / 100)  || 0}</p>
               
                </div>
                
              </div>
            ))}
          </div>
        )}
        </>}


        {tab === "stats" && <Stats username = {user?.name} currency={currency} currencyData={currencyData}/>}

        {tab === "achievements" && <Achievements username = {user?.name}/>}

        </div>
        <br />
        <Modal setShowModal={setShowModalNew} showModal={showModalNew} content={<NewItem currency={currency} currencyData={currencyData}/>} title={"Add New Item"}/>
        <Modal setShowModal={setShowModalItem} showModal={showModalItem} content={<Item item={currentItem} isMyWishlist={isMyWishlist} username={user?.name} currency={currency} currencyData={currencyData}/>} title={<div className='flex gap-2 place-content-between items-center	'>{currentItem?.name} {currentItem?.repeating && <h2 className="text-lg text-colorGray font-bold"><FiRepeat/></h2>}</div>}/>

        <Modal setShowModal={setShowModalDonate} showModal={showModalDonate} content={<Donate user={user} currency={currency} currencyData={currencyData}/>} title={"Donate to " + user?.name}/>


        <Modal setShowModal={setShowModalProfilePhoto} showModal={showModalProfilePhoto} content={<ProfilePhoto username={user?.name}/>} title={"Change Profile Photo"}/>
        <Modal setShowModal={setShowModalBannerPhoto} showModal={showModalBannerPhoto} content={<BannerPhoto username={user?.name} />} title={"Change Banner Photo"}/>

    </div>
    </>
  )
};

export default Wishlist