import React, { useState } from 'react';
import { Link, useNavigate} from 'react-router-dom'
import axios from 'axios';
import { Helmet } from 'react-helmet-async';


const Login = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('')
  const [passwordType, setPasswordType] = useState('password');


  const togglePassword = () => {
    if(passwordType === 'password'){
      setPasswordType('text') 
    }else{
      setPasswordType('password')
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.fancareapp.com/login', {
        name,
        password,
      });


      console.log("Login successful")
      localStorage.setItem('token', response.data.token);
      navigate("/dashboard", {replace: true})
    } catch (error) {
      setErrorMessage("Could not log in - Username or password incorrect");
    }
  };
  return (
    <>
    <Helmet>
      <title>Login | Privacy-first Wishlist for Creators</title>
      <meta name="description" content="Login to Fancare App - Privacy-first Wishlist for Influencers and Content Creators"/>
      <link rel="canonical" href="/login"/>
    </Helmet>
    <section className='w-screen-xl flex flex-col items-center  px-4 py-16  lg:py-40 bg-colorPrimaryDark text-colorBg'>

      <h2 className='text-2xl uppercase font-medium pb-8 text-colorDarkGray'>Log in</h2>

      <h3 className='text-6xl  font-bold '>Welcome to Fancare</h3>

      <div className='flex justify-center -mt-8 pb-8'>
        <svg xmlns="http://www.w3.org/2000/svg" className='w-20' viewBox="0 0 300 300"><path fill="#f59e0b" d="M78.3 189.56L20.88 141.5 40.14 118.5 78.3 150.44 126.1 110.44 173.89 150.44 221.69 110.44 279.12 158.5 259.86 181.5 221.69 149.56 173.89 189.56 126.1 149.56 78.3 189.56z"></path></svg>
      </div>

      <form className="text-left  md:w-2/3 lg:w-1/3 " onSubmit={handleLogin}>
      <p className='text-red-400 '>{errorMessage}</p>
      <br />
      <div className="mb-4">
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline " id="name" name="name" type="name" placeholder={"Username"} required onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="mb-4">
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline " id="password" name="password"  type={passwordType}  placeholder={"Password"} required   onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div className="mb-4 flex gap-4 align-center">
      <input type="checkbox" onClick={togglePassword}/>
      <label className="block text-md mb-2" for="password">
          Show password
      </label>
      </div>

      <div className="flex  justify-between">
      <button className=' bg-colorSecondary w-full focus:outline-none font-medium py-2 px-3  mb-6 text-colorLight rounded ' type="submit" >
          Login
      </button>
      </div>
      <Link to="/Signup"><p>Not a member yet? Sign up here.</p></Link>
      <Link to="/Reset"><p>Forgot password?</p></Link>

    </form>

</section>

  </>

  )
}

export default Login