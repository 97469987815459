import React, {useState } from 'react'
import { getUserData } from '../../helpers/Auth';

const NewEmail = ({setShowModal}) => {
    const [email, setEmail] = useState('');
    const user = getUserData()
    const name = user.username
    const currentEmail = user.email

    const handleReset = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        fetch('https://api.fancareapp.com/new-email/' + name, {                
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token 
        },
            body: JSON.stringify({ name, newEmail : email })

        })
        setShowModal(false)
    };

  return (
    <section className='flex justify-center'>
    <form className="text-left " onSubmit={handleReset}>
    <br />
    <h3 className='text-lg'>Current Email: {currentEmail}</h3>
    <br />
    <p className='text-colorLightGray'>You will receive an email from us upon changing email addressed. You must verify your new email to gain full access to Fancare's features.</p>
    <br />
    <div className="mb-4">
    <label className="block text-md mb-2" for="email">
        New Email
    </label>
    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="email" name="email" type="email" required  onChange={(e) => setEmail(e.target.value)} />
    </div>

    <div className="flex  justify-between">
    <button className=' bg-colorPrimary focus:outline-none font-medium px-6 py-2.5  my-6 text-colorLight rounded-full' type="submit" >
        Reset Email
    </button>
    </div>

    </form>
    </section>
  )
}

export default NewEmail