import React, {useState} from 'react'
import {MdExpandMore } from "react-icons/md";

const Faq = () => {
    const [faq1, setfaq1] = useState(false);
    const [faq2, setfaq2] = useState(false);
    const [faq3, setfaq3] = useState(false);
    const [faq4, setfaq4] = useState(false);
    const [faq5, setfaq5] = useState(false);
  return (
    <section id="faq" className="w-screen-xl flex flex-col items-center text-center px-4 py-16  lg:py-16 bg-colorPrimaryDark text-colorBg">
    <div className=' sm:w-50vw'>
       <h2 className='text-2xl uppercase pb-8 font-medium text-colorDarkGray '>FAQ</h2>
        <h3 className='text-6xl  font-bold'>Frequently Asked Questions.</h3>

        <div className='flex justify-center -mt-8 pb-4'>
          <svg xmlns="http://www.w3.org/2000/svg" className='w-20' viewBox="0 0 300 300"><path fill="#f59e0b" d="M78.3 189.56L20.88 141.5 40.14 118.5 78.3 150.44 126.1 110.44 173.89 150.44 221.69 110.44 279.12 158.5 259.86 181.5 221.69 149.56 173.89 189.56 126.1 149.56 78.3 189.56z"></path></svg>
        </div>
        
        <div className='items-left text-left'>
          <div className='py-8' >
             <button className='text-2xl  font-medium inline-flex pb-2' onClick={()=> setfaq1(!faq1)}>How does it  work? <MdExpandMore /> </button>


            <p className={faq1 ? 'text-lg':'hidden'} >Fancare allows fans to send virtual gifts and support their favorite creators directly. Creators create wishlists, list their most wanted items, and fans can browse and purchase items from those lists. All you need is a Stripe account to make and recieve payments.</p>
            
          </div>
          
          <div className=''>
            <button className='text-2xl font-medium inline-flex pb-2' onClick={() => setfaq2(!faq2)}>Is my personal information secure? <MdExpandMore /> </button>
            
            <p className={faq2 ? 'text-lg':'hidden'} >We take data security seriously. Your personal information is handled with the utmost care and we do not share your data with any third parties. Payments are handled using the secure Stripe payment processor, and we don't store any of your payment data.</p>
          </div>
          <div className='py-8'>
            <button className='text-2xl  text-left font-medium inline-flex pb-2' onClick={() => setfaq3(!faq3)}>Are there any fees or commissions for using Fancare? <MdExpandMore /> </button>
            <br />
            <p className={faq3 ? 'text-lg':'hidden'} >Fancare charges a small transaction fee for processing payments and supporting the platform's maintenance and development. This fee ensures a secure and reliable experience for both creators and fans.</p>
          </div>

          <div className=''>
            <button className='text-2xl font-medium inline-flex pb-2' onClick={() => setfaq4(!faq4)}>Why choose Fancare?<MdExpandMore /> </button>
            <br />
            <p className={faq4 ? 'text-lg':'hidden'} >Fancare is a complete creator support app. We make it as easy as possible for your fans to give you exactly what you need, as easily as possible. We are committed to helping creators thrive, and we believe the best way to do that is to enable your fans to show you how much they care.</p>
          </div>

          <div className='py-8'>
            <button className='text-2xl font-medium inline-flex pb-2' onClick={() => setfaq5(!faq5)}>How can I contact you?<MdExpandMore /> </button>
            <br />
            <div className={faq5 ? 'text-lg':'hidden'}>
            <p className= ''>We're always excited to hear from our users! You can contact us at fancareapp@gmail.com or find us on Twitter <a href="https://twitter.com/FancareApp">@FancareApp.</a> </p>
          </div>
          </div>

        </div>


    </div>
        

  </section>
  )
}

export default Faq