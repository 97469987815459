import React, {useState, useEffect} from 'react'
import { Routes, Route} from 'react-router-dom'


import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';


import Landing from './pages/Landing/Landing'
import Leaderboard from './pages/Leaderboard/Leaderboard';
import Login from './pages/Authentication/Login';
import Signup from './pages/Authentication/Signup';
import Dashboard from './pages/Dashboard/Dashboard';
import Wishlist from './pages/Wishlist/Wishlist';
import VerifyEmail from './pages/Authentication/VerifyEmail';
import ResetPassword from './pages/Authentication/ResetPassword';
import NewPassword from './pages/Authentication/NewPassword';
import GiftComplete from './pages/Wishlist/GiftComplete';
import Privacy from './pages/Legal/Privacy';
import Terms from './pages/Legal/Terms';

import NicheWishlist from './pages/Info/NicheWishlist';
import Alternative from './pages/Info/Alternative';


function App() {
  const [currency, setCurrency] = useState("USD")
  const [currencyData, setCurrencyData] = useState()

  useEffect(() => {
    fetch('https://api.exchangerate-api.com/v4/latest/usd')
      .then(response => response.json())
      .then(data => {
        if(!data) return;
        setCurrencyData(data?.rates);
      })

  },[currency])
  
  return (
    <>

    <Navbar currency={currency} setCurrency={setCurrency} currencyData={currencyData}/>
    <Routes>
      <Route path="/" element={<Landing currency={currency} currencyData={currencyData}/>}/>
      <Route path="/leaderboard" element={<Leaderboard currency={currency} currencyData={currencyData}/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/signup/:name?" element={<Signup/>}/>
      <Route path="/dashboard/:tab?" element={<Dashboard currency={currency} currencyData={currencyData}/>}/>
      <Route path="/:username/" element={<Wishlist currency={currency} currencyData={currencyData}/>}/>
      <Route path="/gifted" element={<GiftComplete/>}/>

      <Route path="/privacy-policy" element={<Privacy/>}/>
      <Route path="/terms" element={<Terms/>}/>


      <Route path="/verify/:token" element={<VerifyEmail/>}/>
      <Route path="/reset" element={<ResetPassword/>}/>
      <Route path="/reset/:token" element={<NewPassword/>}/>

      {/*SEO Info pages*/}

      <Route path="twitch-wishlist" element={<NicheWishlist niche={"Twitch"}/>}/>
      <Route path="twitter-wishlist" element={<NicheWishlist niche={"Twitter"}/>}/>
      <Route path="instagram-wishlist" element={<NicheWishlist niche={"Instagram"}/>}/>
      <Route path="tiktok-wishlist" element={<NicheWishlist niche={"Tiktok"}/>}/>
      <Route path="youtube-wishlist" element={<NicheWishlist niche={"Youtube"}/>}/>

      <Route path="throne-alternative" element={<Alternative platform={"Throne"}/>}/>
      <Route path="buy-me-a-coffee-alternative" element={<Alternative platform={"Buy Me A Coffee"}/>}/>
      <Route path="patreon-alternative" element={<Alternative platform={"Patreon"}/>}/>



    </Routes>
    <Footer/>
    </>
  );
}

export default App;
