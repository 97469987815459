import React from 'react'

//import PaymentStatus from '../../helpers/PaymentStatus'
// const stripePromise = loadStripe("pk_live_51NIu3BIZLxYYDtsFjevjGpnCWtuwKsz4GUlZVmlOMxdNaBqn42yvNj9J94O8EY8Fp9yfiamJzTat1vaUKQnflWAx00pdvmdfKy");

const GiftComplete = () => {

  const username = new URLSearchParams(window.location.search).get(
    'username'
  );


  return (
    <section  className='min-h-screen  flex flex-col justify-start items-center '>
      <div className='w-full  bg-colorLightGray shadow py-8 px-16  flex  items-center justify-end m-0'>
      </div>
      <div className='w-full  px-16  flex flex-col  items-center justify-center m-0'>
      <h1  className='text-xl text-colorDarkGray font-bold mt-8'>
        Checkout Successful!
      </h1>
      <br />
      <a href={window.location.origin + "/" + username}>Return to {username}'s Wishlist</a>
      </div>

    </section>
  )
}

export default GiftComplete