import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, } from 'react-router-dom'
import ReactGA from 'react-ga4'
import './index.css';
import App from './App';
import {HelmetProvider} from 'react-helmet-async'

ReactGA.initialize( "G-NDG3F729J4")

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

