import React from 'react'
import { Link } from 'react-router-dom'
import { BsTwitterX } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";


const Footer = () => {
  return (
    <footer id="contact" className='bg-colorPrimaryDark text-center pt-16 pb-2 px-10 md:px-30vw'>
      <div className='flex items-around flex-col md:flex-row justify-between'>
      
      <div className='flex flex-col py-8 md:text-left'>
        <Link className='text-lg text-colorLight font-medium' to={"/privacy-policy"}>Privacy Policy</Link>
        <Link className='text-lg text-colorLight font-medium' to={"/terms"}>Terms</Link>
        <br />
        <Link className='text-sm text-colorLight font-medium' to={"/twitter-wishlist"}>Twitter Wishlist</Link>
        <Link className='text-sm  text-colorLight font-medium' to={"/tiktok-wishlist"}>Tiktok Wishlist</Link>
        <Link className='text-sm text-colorLight font-medium' to={"/twitch-wishlist"}>Twitch Wishlist</Link>
        <Link className='text-sm text-colorLight font-medium' to={"/instagram-wishlist"}>Instagram Wishlist</Link>
        <Link className='text-sm text-colorLight font-medium' to={"/youtube-wishlist"}>Youtube Wishlist</Link>
        <Link className='text-sm text-colorLight font-medium' to={"/throne-alternative"}>Throne Alternative</Link>
        <Link className='text-sm text-colorLight font-medium' to={"/buy-me-a-coffee-alternative"}>Buy Me A Coffee Alternative</Link>
        <Link className='text-sm text-colorLight font-medium' to={"/patreon-alternative"}>Patreon Alternative</Link>


      </div>

      <div className='flex flex-col py-8 md:text-right '>
      <h2 className='text-xl text-colorLight font-medium'>Contact</h2>
      <p className='text-lg text-colorLight font-medium'>fancareapp@gmail.com</p>

      <div className='flex text-colorLight  text-2xl justify-end mt-10 gap-2'>
        <a className="hover:text-colorSecondary" href="https://twitter.com/FancareApp" target="_blank" rel="noreferrer"  ><BsTwitterX/></a>
        <a className="hover:text-colorSecondary" href="https://www.tiktok.com/@fancareapp" target="_blank" rel="noreferrer" ><FaTiktok/></a>
        <a className="hover:text-colorSecondary" href="https://www.instagram.com/fancareapp/" target="_blank" rel="noreferrer" ><FaInstagram/></a>

      </div>

      </div>



      </div>



        <p className='text-md text-colorLight '>Fancare 2023 ©</p>


    </footer>
  )
}

export default Footer