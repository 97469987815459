import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import {FaTrophy} from 'react-icons/fa'

import display from '../../img/defaultUser.png'
import { Helmet } from 'react-helmet-async';

const Leaderboard = ({currency, currencyData}) => {
  const [allUsers, setAllUsers] = useState()
  const [monthlyUsers, setMonthlyUsers] = useState()

  let today = new Date();
  let month = today.toLocaleString("en-US", {
    month: "long",
  });
  let year = today.getFullYear()

  useEffect(() =>{
    fetch('https://api.fancareapp.com/getTopUsers/')
    .then(res => res.json())
    .then(users => {
      console.log(users)
      setAllUsers(users)})

      fetch('https://api.fancareapp.com/getTopMonthlyUsers/')
      .then(res => res.json())
      .then(users => {
        console.log(users)
        setMonthlyUsers(users)})
  }, [])


  return (
    <>
   {/* <Helmet>
      <title>Leaderboard | Can you rank among the Top Creators this month? </title>
      <meta name="description" content="Receive the most gifts from fans - Can you rank among the Top Creators this month?"/>
      <link rel="canonical" href="/Leaderboard"/>
    </Helmet>

  */}
      <section className=' py-10vh '>
        <div className='flex flex-col items-center  pb-16 px-4'>
          <h1 className="text-2xl text-colorDarkGray font-bold">Can you rank among the  <u className='decoration-4 decoration-colorSecondary'>Top Creators</u>  this month?</h1>
        </div>
        <div className='flex justify-center gap-16 flex-col md:flex-row px-4 sm:px-16 '>


          <div className='flex flex-col gap-4'>
            <div  className="bg-white rounded-lg shadow p-4  flex flex-col ">
              <h1 className="text-lg flex items-center text-colorGray  font-bold">{month}'s Top Creators</h1>
              <div className='overflow-y-auto overflow-x-hidden	 h-50vh bg-colorBg  my-4 rounded-lg border' >
                    {!monthlyUsers?.length &&   <h1 className="text-lg flex items-center text-colorGray  font-bold p-4 md:w-25vw w-full">Nothing here yet.</h1>}
                    {monthlyUsers?.filter(user => user.userData.length).map((user, i) => (
                              <>
                              <Link to={"/" + user._id}>
                                  <div className={i%2-1 ? 'flex items-center justify-between gap-4 p-4 md:w-25vw w-full ' : 'flex items-center  justify-between gap-4 md:w-25vw w-full p-4 bg-white'}>
                                    <div className='flex gap-4'>

                                      <img src={user.userData[0]?.profilePictureUrl ? user.userData[0].profilePictureUrl : display} alt="" className='rounded-full  h-10 shadow ' />
                                      <div>
                                        <h3><b>{i+1}.</b> {user._id}</h3>
                                        {currencyData &&  <p>Has been gifted <b>{currency !== "USD" && <i>est.</i> }  {currency} {(Math.round((user.totalGifted + Number.EPSILON) * 100) / 100) * currencyData[currency] || 0}</b> in {month} {year}</p>
}
                                      </div>
                                      </div>

                                      {i === 0 &&  <FaTrophy className='text-2xl text-yellow-500'/>  }
                                      {i === 1 &&  <FaTrophy className='text-2xl text-zinc-300'/>  }
                                      {i === 2 &&  <FaTrophy className='text-2xl text-yellow-700'/>  }

                                  </div>
                              </Link>
                              
                              </>

                          ))}
                      </div>
              </div>
          </div>

          <div className='flex flex-col gap-4'>
            <div  className="bg-white rounded-lg shadow p-4 flex flex-col ">
              <h1 className="text-lg flex items-center text-colorGray  font-bold">All-time Top Creators</h1>
              <div className='overflow-y-auto overflow-x-hidden	 h-50vh bg-colorBg  my-4 rounded-lg border' >
                    {!allUsers?.length &&   <h1 className="text-lg flex items-center text-colorGray  font-bold p-4 md:w-25vw w-full">Nothing here yet.</h1>}
                    {allUsers?.filter(user => user.userData.length).map((user, i) => (
                              <>
                              <Link to={"/" + user._id}>
                                  <div className={i%2-1 ? 'flex items-center justify-between gap-4 p-4 md:w-25vw w-full ' : 'flex items-center justify-between gap-4 md:w-25vw w-full p-4 bg-white'}>
                                      <div className='flex gap-4'>
                                        <img src={user.userData[0]?.profilePictureUrl ? user.userData[0].profilePictureUrl : display} alt="" className='rounded-full  h-10 shadow ' />
                                        <div>
                                          <h3><b>{i+1}.</b> {user._id}</h3>
                                          {currencyData && <p>Has been gifted <b>{currency !== "USD" && <i>est.</i> }  {currency} {(Math.round((user.totalGifted + Number.EPSILON) * 100) / 100) * currencyData[currency] || 0}</b></p>
}
                                        </div>
                                      </div>

                                      {i === 0 &&  <FaTrophy className='text-2xl text-yellow-500'/>  }
                                      {i === 1 &&  <FaTrophy className='text-2xl text-zinc-300'/>  }
                                      {i === 2 &&  <FaTrophy className='text-2xl text-yellow-700'/>  }
                                  </div>
                              </Link>
                              
                              </>

                          ))}
                      </div>
              </div>
          </div>

        </div>
      
      </section>


    </>


  )
}

export default Leaderboard