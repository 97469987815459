import { Fragment} from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Converter({selected, setSelected, currencyData}) {
  const currencyList = {"USD": {description: "United States Dollar",
                                symbol: "$"}, 
                        "EUR": {description: "Euro",
                        symbol: "€"}, 
                        "JPY": {description: "Japanese yen",
                        symbol: "JP¥"}, 
                        "GBP": {description: "Pound Sterling",
                        symbol: "£"}, 
                        "CNY": {description: "Chinese yuan",
                        symbol: "CN¥"},
                        "AUD": {description: "Australian dollar",
                        symbol: "A$"},
                        "CAD": {description: "Canadian dollar",
                        symbol: "C$"},
                        "HKD": {description: "Hong Kong dollar",
                        symbol: "HK$"},
                        "SGD": {description: "Singapore dollar",
                        symbol: "S$"},
                        "SEK": {description: "Swedish krona",
                        symbol: "kr"},
                        "KRW": {description: "South Korean won",
                        symbol: "₩"},
                        "NOK": {description: "Norwegian krone",
                        symbol: "kr"},
                        "NZD": {description: "New Zealand dollar",
                        symbol: "NZ$"},
                        "INR": {description: "Indian rupee",
                        symbol: "₹"},
                        "MXN": {description: "Mexican peso",
                        symbol: "$"},
                        "TWD": {description: "New Taiwan dollar",
                        symbol: "NT$"},                    
                        "ZAR": {description: "South African rand",
                        symbol: "R"},                     
                        "BRL": {description: "Brazilian real",
                        symbol: "R$"},   
                        "DKK": {description: "Danish krone",
                        symbol: "kr"},   
                        "PLN": {description: "Polish złoty",
                        symbol: "zł"},               
                      
                      }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm  text-gray-900 shadow-sm ">
          {selected.toUpperCase()}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >

        
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg  focus:outline-none overflow-y-auto	 h-50vh">
          <div className="py-1">

            { currencyList && Object.keys(currencyList).map((keyName, i) => (
                 <Menu.Item key={i}>
                 {({ active }) => (
                   <button
                     className={classNames(
                       active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                       'block px-4 py-2 text-sm'
                     )}
                     onClick={() => setSelected(String(keyName))}
   
                   
                   >
                     {String(keyName)}  - <b>{currencyList[keyName].symbol }</b>  {currencyList[keyName].description} 
                   </button>
                 )}
               </Menu.Item>
            ))}
           

           
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
