import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom'
import { isAuthenticated, getUserData } from '../../helpers/Auth';
import axios from 'axios';


const UpdateDetails = () => {
    const navigate = useNavigate()
    const [user, setUser] = useState()
    const [displayName, setDisplayName] = useState("")
    const [bio, setBio] = useState("")
    const [userData, setUserData] = useState()
    const [errorMessage, setErrorMessage] = useState('')

   

    const handleUpdate = async (e) => {
      e.preventDefault();
      const token = localStorage.getItem('token');

      try {
        await axios.post('https://api.fancareapp.com/user/update/' + userData?.username, {
          name: userData?.username,
          displayName,
          bio,
        }, 
        { headers: {'Authorization': token }} 
        );
        navigate("/" + user?.name , {replace: true})
      } catch (error) {
        setErrorMessage("Problem updating details, ensure character limits are not exceeded.");
      }
    };


    useEffect(() => {
        setUserData(getUserData())
      }, []);


    useEffect(() => {
      if (userData?.username) {
        fetch('https://api.fancareapp.com/user/' + userData?.username)
        .then(res => res.json())
        .then(user => {
          setUser(user)
          setDisplayName(user?.displayName)
          setBio(user?.bio)
        })
      }


    },[userData]);
    
    return (
      <>
      {isAuthenticated() && 
      <section className='flex flex-col justify-center '>
        <p className='text-red-400 '>{errorMessage}</p>
        <br />
        <form className="text-left  md:w-2/3 lg:w-1/3 " onSubmit={handleUpdate}>

        <div className="mb-4">
        <label className="block text-md mb-2 font-bold" for="name">
            Display Name
        </label>
        <p className='text-sm text-colorLightGrey'>Limit: 40 characters</p>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" maxlength="40"  id="name" name="name" type="name" required value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
        </div>
        <div className="mb-4">
        <label className="block text-md mb-2 font-bold" for="message">
            Bio
        </label>
        <p className='text-sm text-colorLightGrey'>Limit: 400 characters</p>
        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline mb-4" maxlength="400" id="bio" name="bio" type="bio"  value={bio} onChange={(e) => setBio(e.target.value)} />
        </div>
  
        <div className="flex  justify-between">
        <button className=' bg-colorPrimary focus:outline-none font-medium px-6 py-2.5  text-colorLight rounded-full' type="submit" >
            Update 
        </button>
        </div>

  
      </form>
  
  </section>
}
    </>)
}

export default UpdateDetails