import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const navigate = useNavigate()
    const [name, setName] = useState('');

    const handleReset = (e) => {
        e.preventDefault();
        fetch('https://api.fancareapp.com/reset/', {                
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
            body: JSON.stringify({ name })

        }).then(res => {
          if (res.ok){
            navigate('/')
          }
        })
    };



  return (
    <section className='min-h-screen py-20vh flex justify-center'>
    <form className="text-left  md:w-2/3 lg:w-1/3 " onSubmit={handleReset}>
    <br />
    <div className="mb-4">
    <label className="block text-md mb-2" for="email">
        Username
    </label>
    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="name" name="name" type="name" required  onChange={(e) => setName(e.target.value)} />
    </div>

    <div className="flex  justify-between">
    <button className=' bg-colorPrimary focus:outline-none font-medium px-6 py-2.5  my-6 text-colorLight rounded-full' type="submit" >
        Send Reset Link
    </button>
    </div>

    </form>
    </section>
  )
}

export default ResetPassword