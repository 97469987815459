import React, {useState} from 'react'
import { useNavigate, useParams} from 'react-router-dom';

const NewPassword = () => {
    const navigate = useNavigate()
    const [password, setPassword] = useState()
    const [password2, setPassword2] = useState()
    const [error, setError] = useState()

    const { token } = useParams()

    const handleReset = (e) => {
        e.preventDefault()
        if (password === password2) {
            fetch('https://api.fancareapp.com/reset/' + token, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                navigate("/login")
            })
        }
        else {
            setError("Passwords do not match")
        }
    }



  return (
    <section className='min-h-screen py-20vh flex justify-center'>

    <form className="text-left  md:w-2/3 lg:w-1/3 " onSubmit={handleReset}>
    <p className='text-red-400 '>{error}</p>

    <br />
    <div className="mb-4">
    <label className="block text-md mb-2" for="email">
        New Password
    </label>
    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="password" name="password" type="password" required  onChange={(e) => setPassword(e.target.value)} />
    </div>
    <div className="mb-4">

    <label className="block text-md mb-2" for="email">
        Retype Password
    </label>

    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="password2" name="password" type="password" required  onChange={(e) => setPassword2(e.target.value)} />
    </div>

    <div className="flex  justify-between">
    <button className=' bg-colorPrimary focus:outline-none font-medium px-6 py-2.5  my-6 text-colorLight rounded-full' type="submit" >
        Reset Password
    </button>
    </div>

    </form>
    </section>
)}

export default NewPassword