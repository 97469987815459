import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { isAuthenticated, getUserData } from '../../helpers/Auth'

import Logo from '../../img/fancare.png'
import Converter from './CurrencyConverter'

const Navbar = ({currency, setCurrency, currencyData}) => {
    const navigate = useNavigate()
    const [isExpanded, setIsExpanded] = useState(false);
    const [scrolled, setScrolled] = useState(false); 
    const [userData, setUserData] = useState(false); 
    const token = localStorage.getItem('token');  

    const handleLogout = () => {
      // Clear the token from local storage or cookies
      localStorage.removeItem('token');
      // Redirect the user to the login page or any desired route
      navigate('/login');
    };

    useEffect(() => {
        const handleScroll = () => {
          const isScrolled = window.scrollY > 0;
          setScrolled(isScrolled);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      useEffect(() => {
        setUserData(getUserData())
      }, [token]);
    
  return (
    <nav className={scrolled ? "sticky top-0 border-gray-200 bg-colorBg shadow-md transition z-10"  : "sticky top-0 border-gray-200 bg-colorBg transition z-10"  }>
  <div className="max-w-screen-xl flex items-center justify-between mx-auto  px-8 py-4 md:px-16">
    <Link to='/' className="flex items-center">
       <img src={Logo} alt="Fancare Logo" className='w-24'/>
      <span className="self-center text-2xl font-semibold whitespace-nowrap text-colorDarkGray sm:block hidden">Fancare</span> 
    </Link>
    
    <div className='flex'>
      <div className="block sm:hidden">
      <Converter  selected={currency} setSelected={setCurrency} currencyData={currencyData}/>
      </div>

      <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" onClick = {() => setIsExpanded(!isExpanded)}>
        <span className="sr-only">Open main menu</span>
        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
      </button>

    </div>
   
    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
      <ul className="font-medium flex items-center flex-col p-8 md:p-0 mt-4 border rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:border-0 text-colorDarkGray">
        <li className='hover:underline decoration-2 decoration-colorSecondary'>      <Link to='/Leaderboard'>Leaderboard</Link>
        </li>

        {isAuthenticated() ? 
        <>
          <li className='hover:underline decoration-2 decoration-colorSecondary'>
          <Link to={"/" + userData?.username}>Profile</Link>
          </li>
          <li className='hover:underline decoration-2 decoration-colorSecondary'>
          <Link to='/Dashboard'>Dashboard</Link>
          </li>
          <li className='hover:underline decoration-2 decoration-colorSecondary'><button className='hover:underline decoration-2 decoration-colorSecondary' onClick={handleLogout}> Log Out</button>
          </li>
          
        </>

        :
          <>
            <li className='hover:underline decoration-2 decoration-colorSecondary'>
            <Link to='/Login'>Login</Link>
            </li>
            <li className='hover:underline decoration-2 decoration-colorSecondary'>
            <Link to='/Signup'>Sign Up</Link>
            </li>
          </>
        }
        <li><Converter selected={currency} setSelected={setCurrency}  currencyData={currencyData}/></li>

      </ul>
    </div>


  </div>
  <div className={isExpanded ? ' h-screen flex gap-8 flex-col items-end m-2 md:hidden transition' : 'hidden transition'}>
        <ul className="font-medium flex flex-col p-4 md:p-0 mt-4  rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 gap-8">
            <li>
            <Link to='/Leaderboard'>Leaderboard</Link>
            </li>

            {isAuthenticated() ? 
              <>
                <li>
                <Link to={"/" + userData?.username}>Profile</Link>
                </li>
                <li>
                <Link to='/Dashboard'>Dashboard</Link>
                </li>
                <li ><button onClick={handleLogout}> Log Out</button>
                </li>
              </>

            :
              <>
                <li>
                <Link to='/Login'>Login</Link>
                </li>
                <li>
                <Link to='/Signup'>Sign Up</Link>
                </li>
              </>
            }

        </ul>
      </div>
</nav>
  )
}

export default Navbar