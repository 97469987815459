import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';


import Modal from '../../components/Modal/Modal'
import UpdateItem from './Update/UpdateItem'
import StripePayment from './StripePayment';
import { isAuthenticated, getUserData } from '../../helpers/Auth'


const Item = ({item, isMyWishlist, username, currency, currencyData}) => {
  const navigate = useNavigate()
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showModalCheckout, setShowModalCheckout] = useState(false)
  const [userData, setUserData] = useState(getUserData)
  const [giftSender, setGiftSender] = useState(userData?.username ||"Anonymous")
  const [giftMessage, setGiftMessage] = useState("")


  const handleDelete= async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    await axios.delete('https://api.fancareapp.com/wishlist/delete' + item?._id + '/' + username, {headers : {'Authorization': token}} )
    .then(() => {
    console.log("Delete Item Successful")
    navigate(0)
    }).catch((error) => {
      console.log('Error deleting item :', error);

    })


  };

  const handleCheckout = async () => {
    await fetch('https://api.fancareapp.com/checkout' , {method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
     body: JSON.stringify({username,  itemId : item?._id, currency: "usd", fanname: userData?.username, fanAlias: giftSender, message: giftMessage})})
     .then((response) => {
      console.log(response)
      return response.json();
     }).then((response) => {
        
      if(JSON.parse(response)["url"]){
        window.location = JSON.parse(response)["url"];
      }
     })

}



  return (
    <div className="md:w-20vw p-4 flex flex-col m-auto">
        <div className="mb-4 flex justify-center">
          <img
            src={item.imageUrl}
            alt={item.name}
            className="w-full h-auto rounded-l md:w-20vw"
          />
        </div>
        <div className="flex-1 ">
          <p className="text-lg text-colorLightGray mt-4">{item.description}</p>
          <p className="text-lg text-colorGray my-2 font-bold">{currency !== "USD" && <i>est.</i> }  {currency} {(Math.round((item.price + Number.EPSILON)  * 1.1 *   currencyData[currency] *  100) / 100)  || 0}</p>
          {currency !== "USD" && <p className="text-sm text-colorGray my-2 font-bold">USD {(Math.round((item.price + Number.EPSILON)  * 1.1 *   100) / 100)  || 0}</p>}

        </div>
        {isMyWishlist() && isAuthenticated() ? 
          <>
          <button onClick={() => setShowModalEdit(true)} className=' bg-colorPrimary focus:outline-none font-medium px-6 py-2.5  my-6 text-colorLight rounded-full' >Edit Item</button>
          <button onClick={() =>{
                    setShowModalDelete(true)}}>Delete</button>
        </> :
        <>       
          {!isAuthenticated() && <>
            <div className="my-4 ">
            <label className="block text-md mb-2" for="name">
                From: 
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="name" name="name" type="name" required value={giftSender} onChange={(e) => setGiftSender(e.target.value)} />
          </div>
          </>}

          <div className="mb-4 ">
            <label className="block text-md mb-2" for="name">
                Optional Message
            </label>
            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="name" name="name" type="name" required value={giftMessage} onChange={(e) => setGiftMessage(e.target.value)} />
          </div>
         <button className=' bg-colorPrimary focus:outline-none font-medium px-6 py-2.5  my-6 text-colorLight rounded-full' onClick={() => handleCheckout()}>Gift this item</button>
        </>}
        <Modal setShowModal={setShowModalDelete} showModal={showModalDelete} content={
        <><p>Are you sure you want to delete?</p>
          <button className=' bg-red-400 focus:outline-none font-medium px-6 py-2.5  my-6 text-white rounded-full' onClick={(e) => handleDelete(e)}>Delete</button>
        </>
        
        } title={<p>Delete {item?.name }?</p>}/>
        <Modal setShowModal={setShowModalCheckout} showModal={showModalCheckout} content={ 
          <StripePayment username={username}  itemId={item._id} currency={"usd"} fanname={userData?.username} fanAlias={giftSender} message={giftMessage} />  
        } title={<p>Gift {item?.name}</p>}/>
          
        <Modal setShowModal={setShowModalEdit} showModal={showModalEdit} content={<UpdateItem item={item} currency={currency} currencyData={currencyData}/>} title={<p>Edit {item?.name}</p>}/>
      </div>
  )
}

export default Item