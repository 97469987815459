import jwt from "jwt-decode"

export const isAuthenticated = () => {
    const token = localStorage.getItem('token');
  
    // Check if the token exists
    if (token) {
      // Check if the token is expired
      const decodedToken = jwt(token);
      if (decodedToken && decodedToken.exp * 1000 > Date.now()) {
        return true;
      }
    }
  
    return false;
  };

export const getUserData = () => {
  const token = localStorage.getItem('token');
  // Check if the token exists
  if (token) {
    // Check if the token is expired
    const decodedToken = jwt(token);
    if (decodedToken && decodedToken.exp * 1000 > Date.now()) {
      return {username: decodedToken.user.name, displayName: decodedToken.user.displayName, email: decodedToken.user.email, bio: decodedToken.user.bio}
    }
  }
}