import React, { useState} from 'react';
import { Link, useNavigate, useParams} from 'react-router-dom'
import axios from 'axios';
import { Helmet } from 'react-helmet-async';


const Signup = () => {
  const {name} = useParams()
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState(name );
  const [password, setPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [errorMessage, setErrorMessage] = useState('')

  const isUserNameValid = (username) => {
    /* 
      Usernames can only have: 
      - Lowercase Letters (a-z) 
      - Numbers (0-9)
      - Dots (.)
      - Underscores (_)
    */
    const res = /^[a-z0-9]+$/.exec(username);
    const valid = !!res;
    return valid;
  }


  const togglePassword = () => {
    if(passwordType === 'password'){
      setPasswordType('text') 
    }else{
      setPasswordType('password')
    }
  }

  const handleSignup = async (e) => {
    e.preventDefault();
    let usernameLower = username.toLowerCase();
    if(!isUserNameValid(usernameLower)){
      setErrorMessage("Please choose a username with only lowercase letters and numbers.")
      return
    }
    if(username.length > 40){
      setErrorMessage("Please ensure your username is under 40 characters.")
      return
    }

    if(password.length <8){
      setErrorMessage("Password must be at least 8 characters.")
      return
    }

    try {
      await axios.post('https://api.fancareapp.com/register', {
        email,
        name : usernameLower,
        password,
        passwordMatch
      });
      try{
          const res = await axios.post('https://api.fancareapp.com/login', {
              name: usernameLower, 
              password
          });
          console.log("Login after registration successful")
          localStorage.setItem('token', res.data.token);
          navigate("/dashboard", {replace: true})

      } catch(error){
         return
      }


    } catch (error) {
      setErrorMessage(error.response.data.message)
    }
  };
return (
  <>
    <Helmet>
      <title>Sign Up | Create an account and start receiving gifts today! </title>
      <meta name="description" content="Sign up to receive gifts - Privacy-first Wishlist for Influencers and Content Creators"/>
      <link rel="canonical" href="/Signup"/>
    </Helmet>
      <section className='w-screen-xl flex flex-col items-center  px-4 py-16  lg:py-40 bg-colorPrimaryDark text-colorBg'>

      <h2 className='text-2xl uppercase font-medium pb-8 text-colorDarkGray'>Sign up</h2>

      <h3 className='text-6xl  font-bold '>Create an account</h3>

      <div className='flex justify-center -mt-8 pb-8'>
        <svg xmlns="http://www.w3.org/2000/svg" className='w-20' viewBox="0 0 300 300"><path fill="#f59e0b" d="M78.3 189.56L20.88 141.5 40.14 118.5 78.3 150.44 126.1 110.44 173.89 150.44 221.69 110.44 279.12 158.5 259.86 181.5 221.69 149.56 173.89 189.56 126.1 149.56 78.3 189.56z"></path></svg>
      </div>

      <form className="text-left  md:w-2/3 lg:w-1/3 " onSubmit={handleSignup}>
      <p className='text-red-400 '>{errorMessage}</p>
      <br />
      <div className="mb-4">
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline lowercase" id="name" name="name" type="name" required  placeholder={"Username"} defaultValue={name} onChange={(e) => setUsername(e.target.value)} />
      </div>
      <div className="mb-4">
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="email" name="email" type="email" required  placeholder={"Email"}   onChange={(e) => setEmail(e.target.value)} />
      </div> 
      <div className="mb-4">
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="password" name="password" type={passwordType} required   placeholder={"Password: Must be at least 8 characters"}  onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div className="mb-4">
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="password2" name="password" type={passwordType} required  placeholder={"Retype Password"}   onChange={(e) => setPasswordMatch(e.target.value)} />
      </div>
      <div className="mb-4 flex gap-4 align-center">
      <input type="checkbox" onClick={togglePassword}/>
      <label className="block text-md mb-2" for="password">
          Show passwords
      </label>
      </div>


      <div className="flex  justify-between">
      <button className=' bg-colorSecondary w-full focus:outline-none font-medium py-2 px-3  mb-6 text-colorLight rounded ' type="submit" >
          Register
      </button>
      </div>
      <Link to="/Login"><p>Already a member? Log in here.</p></Link>

      </form>


      </section>

  </>
  )
}

export default Signup