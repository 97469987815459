import React from 'react'

const Terms = () => {
  return (
    <section id="features" className="w-screen-xl flex flex-col items-center  px-4 py-16  lg:py-40 bg-colorPrimaryDark text-colorBg">
        <div className=' sm:w-50vw'>
          <h3 className='text-6xl text-center font-bold '>Terms of Use</h3>
          <div className='flex justify-center -mt-8 pb-8'>
            <svg xmlns="http://www.w3.org/2000/svg" className='w-20' viewBox="0 0 300 300"><path fill="#f59e0b" d="M78.3 189.56L20.88 141.5 40.14 118.5 78.3 150.44 126.1 110.44 173.89 150.44 221.69 110.44 279.12 158.5 259.86 181.5 221.69 149.56 173.89 189.56 126.1 149.56 78.3 189.56z"></path></svg>
          </div>
          <p className= 'text-xl font-bold'>Last updated: 11/10/2023</p>
          <p className='opacity-70 text-lg'>Please read these Terms of Service ("Terms") carefully before using the Fancare website (the "Service") operated by the Fancare Team ("us," "we," or "our").</p>
          
          
          <br />
          <h2 className=' text-xl font-bold'>1. Acceptance of Terms</h2>
          <p className='text-md'>By accessing or using the Service, you agree to comply with these Terms. If you do not agree with these Terms, please do not use the Service.</p>

          <br />
          <h2 className=' text-xl font-bold'>2. Changes to Terms</h2>
          <p className='text-md'>We reserve the right to modify or replace these Terms at any time. Your continued use of the Service following changes to the Terms constitutes your acceptance of those changes.</p>

          <br />
          <h2 className=' text-xl font-bold'>3. User Account and Information</h2>
          <p className='text-md'>To use certain features of the Service, you may be required to create a user account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>


          <br />
          <h2 className=' text-xl font-bold'>4. Content</h2>
          <p className='text-md'>The Service may allow you to post, link, share, and otherwise make available certain information, text, images, or other material ("Content"). You are solely responsible for the Content you post on the Service.</p>
          <p className='text-md'>We do not allow any depictions of nudity, implied nudity, or sexual activity. </p>


          <br />
          <h2 className=' text-xl font-bold'>5. Third-Party Payment Processing</h2>
          <p className='text-md'>We utilize a third-party payment processing service provided by Stripe. By using the Service, you agree to comply with Stripe's terms of service. We are not responsible for any issues or disputes related to payments, refunds, or transactions processed through Stripe.</p>


          <br />
          <h2 className=' text-xl font-bold'>6. Privacy</h2>
          <p className='text-md'>Your use of the Service is also governed by our Privacy Policy, which can be found <a className="text-colorSecondary" href="/privacy-policy">here</a>.</p>

          <br />
          <h2 className=' text-xl font-bold'>7. Termination</h2>
          <p className='text-md'>We may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason, including without limitation if you breach the Terms.</p>


          <br />
          <h2 className=' text-xl font-bold'>8. Limitation of Liability</h2>
          <p className='text-md'>In no event shall Fancare or its directors, employees, or affiliates be liable for any direct, indirect, special, incidental, or consequential damages arising out of the use or inability to use the Service.</p>


          <br />
          <h2 className=' text-xl font-bold'>9. Contact Us</h2>
          <p className='text-md'>If you have any questions about these Terms, please contact us at fancareapp@gmail.com</p>


      </div>
    </section>
  )
}

export default Terms