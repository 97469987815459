import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom'
import { isAuthenticated, getUserData } from '../../helpers/Auth';
import axios from 'axios';

import fancarelogo from '../../img/fancarelogo.png'

const NewItem = ({currency, currencyData}) => {
    const navigate = useNavigate()
    const [user, setUser] = useState()
    const [itemPhoto, setItemPhoto] = useState()
    const [preview, setPreview] = useState()
    const [itemName, setItemName] = useState("");
    const [description, setdescription] = useState("");
    const [price, setPrice] = useState("");
    const [repeating, setRepeating] = useState(false);

    const [userData, setUserData] = useState()
    const handleNewItem = async (e) => {
      e.preventDefault();
      const token = localStorage.getItem('token');
      const formData = new FormData()
      formData.append('image', itemPhoto);
      formData.append('username', userData.username);
      formData.append('name', itemName);
      formData.append('description', description);
      formData.append('price', price);
      formData.append('repeating', repeating);
      try {
        await axios.post('https://api.fancareapp.com/wishlist/add/' + userData?.username, 
        formData,
        { headers: {'Content-Type': 'multipart/form-data', 'Authorization': token}},
);
  

        console.log("Add Item Successful")
        navigate(0)
      } catch (error) {
        console.log('Error adding item :', error);
      }
     
    };

    const handleProfilePhotoUpload = async (event) => {
      const file = event.target.files[0];
      const objectUrl = URL.createObjectURL(file)
      setPreview(objectUrl)
      setItemPhoto(file);
    };
  

    useEffect(() => {
        setUserData(getUserData())
        console.log(user)
      }, []);


    useEffect(() => {
      fetch('https://api.fancareapp.com/user/' + userData?.username)
      .then(res => res.json())
      .then(user => {
        console.log("got the data")
        setUser(user)
      })

    },[userData]);
    
    return (
      <div className=' md:w-20vw flex flex-col m-auto'>
      {isAuthenticated() && 
        <>
        <br />
        <div className="mb-4">
            {itemPhoto ?
                  <img
                    src={preview}
                    className="w-full px-8 h-auto rounded-l"
                    alt="Selected Item"
                  />
                  :
                  <img
                  src={fancarelogo}
                  className="w-full px-8 h-auto rounded-l"
                  alt="Selected Itom"

                />
            } 
            <br />
            <input className="w-full file:bg-colorPrimary file:border-none my-6 file:text-colorLight file:rounded file:px-5 file:py-2.5 file:transition file:duration-150 file:ease-in-out hover:file:bg-colorBg hover:file:text-colorPrimary" type="file" accept="image/*" onChange={handleProfilePhotoUpload}  />

        </div> 
       
        <form className="text-left w-full px-8 " onSubmit={handleNewItem}>
            <div className="mb-4 ">
            <label className="block text-md mb-2" for="name">
                Item Name
            </label>
            <p className='text-sm text-colorLightGrey'>Limit: 20 characters</p>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" maxlength="20" id="name" name="name" type="name" required value={itemName} onChange={(e) => setItemName(e.target.value)} />
            </div>
            <div className="mb-4">
            <label className="block text-md mb-2" for="message">
                Item Description
            </label>
            <p className='text-sm text-colorLightGrey'>Limit: 400 characters</p>
            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline"maxlength="400" id="description" name="description" type="description" required value={description} onChange={(e) => setdescription(e.target.value)} />
            </div>

            <div className="mb-4">
            <label className="block text-md mb-2" for="message">
                Item Price ($)
            </label>
            <p className='text-sm text-colorLightGrey'>Min: $5</p>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline mb-4" min="5" max="100000" id="price" name="price" type="number"   step="0.01"  required value={price} onChange={(e) => setPrice(e.target.value)} />
            <p className="">User pays: ${Math.round((price * 1.1) * 100) / 100}</p>
            {currency !== "USD" &&  <p className="">{currency !== "USD" && <i>est.</i> }  {currency} {(Math.round((price )  * 1.1 *   currencyData[currency] *  100) / 100)}</p>
}

            </div>
            <div className="mb-4">
            <label className=" text-md mb-2 mr-4" for="message">
                Repeat:
            </label>
            <input  id="repeating" name="repeating" type="checkbox"  onChange={(e) => setRepeating(!repeating)} />
            <p className='text-colorLightGray text-sm'>A repeating gift will not disappear when it is purchased.</p>
            </div>
    
    
            <div className="flex  justify-between">
            <button className=' bg-colorPrimary focus:outline-none font-medium px-6 py-2.5  my-6 text-colorLight rounded-full' type="submit" >
                Add
            </button>
            </div>

    
        </form>
  
  </>
}
    </div>)
}

export default NewItem