import React, { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BannerPhoto = ({username}) => {
  
  const navigate = useNavigate()
  const [preview, setPreview] = useState();
  const [defaultPhoto, setDefaultPhoto] = useState(null);
  
  
  const handleUpdate = async (e) => {
      e.preventDefault();
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('image', defaultPhoto);
      formData.append('name', username)
      try {
        await axios.post('https://api.fancareapp.com/user/update/bannerphoto/' + username, 
          formData,
          { headers: {'Content-Type': 'multipart/form-data', 'Authorization': token } }
        );  
        navigate(0)
      } catch (error) {
        console.log('Error updating profile photo :', error);
      }
    };



  const handleProfilePhotoUpload = async (event) => {
    const file = event.target.files[0];
    const objectUrl = URL.createObjectURL(file)
    setPreview(objectUrl)
    setDefaultPhoto(file);
  };



return (
  <div>
 <input className="w-full file:bg-colorPrimary file:border-none my-6 file:text-colorLight file:rounded file:px-5 file:py-2.5 file:transition file:duration-150 file:ease-in-out hover:file:bg-colorBg hover:file:text-colorPrimary" type="file" accept="image/*" onChange={handleProfilePhotoUpload}  />
    {defaultPhoto && (
      <div>
        <br />
        <h3>Preview:</h3>
        <div className='flex w-30vw'>
        <img className='w-full  ' src={preview} alt="" />
        </div>

        
      </div>
    )}
    {/* Other input fields for updating details */}
    {defaultPhoto && <button onClick={handleUpdate}>Save Changes</button> }



  </div>
)
}



export default BannerPhoto