import React from 'react'

const Privacy = () => {
  return (
    <section id="features" className="w-screen-xl flex flex-col items-center  px-4 py-16  lg:py-40 bg-colorPrimaryDark text-colorBg">
        <div className='sm:w-50vw'>
          <h1 className='text-6xl text-center  font-bold '>Privacy Policy</h1>
          <div className='flex justify-center -mt-8 pb-8'>
            <svg xmlns="http://www.w3.org/2000/svg" className='w-20' viewBox="0 0 300 300"><path fill="#f59e0b" d="M78.3 189.56L20.88 141.5 40.14 118.5 78.3 150.44 126.1 110.44 173.89 150.44 221.69 110.44 279.12 158.5 259.86 181.5 221.69 149.56 173.89 189.56 126.1 149.56 78.3 189.56z"></path></svg>
          </div>
          <p className='opacity-70 text-lg'>This privacy policy will explain how Fancare uses the personal data we collect from you when you use our website.</p>

          <br />
          <h2 className=' text-xl font-bold'>What data do we collect?</h2>
          <p className='text-md'>Fancare collects the following data:</p>
            <ul className='list-disc list-inside'>
              <li>Personal identification information (email address)</li>
            </ul>


          <br />
          <h2 className=' text-xl font-bold'>How do we collect your data?</h2>
          <p className='text-'>You directly provide Fancare with most of the data we collect. We collect data and process data when you:</p>
            <ul className='list-disc list-inside'>
              <li>Register online or place an order for any of our products or services.</li>
              <li>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.</li>
            </ul>

            <br />
          <h2 className=' text-xl font-bold'>How will we use your data?</h2>
          <p className='text-md'>Fancare collects your data so that we can:</p>
            <ul className='list-disc list-inside '>
              <li>Process your order and manage your account.</li>
              <li>Email you with updates to your account.</li>
              <li>Email you to notify you of received/send donations and gifts.</li>

            </ul>
          <p className='text-md'>When Fancare processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases.</p>


          <br />
          <h2 className=' text-xl font-bold'>How do we store your data?</h2>
          <p className='text-md'>Fancare may store your data on servers provided by third party hosting vendors with whom we have contracted. </p>
          <p className='text-md'>Fancare will keep your data until your Fancare account is terminated. Once this has happened, we will delete your data from our database.</p>

          <br />
          <h2 className=' text-xl font-bold'>Marketing</h2>
          <p className='text-md'>Fancare would like to send you information about services of ours that we think you might like. </p>
          <p className='text-md'>If you have agreed to receive marketing, you may always opt out at a later date.</p>

          <br />
          <h2 className=' text-xl font-bold'>What are your data protection rights?</h2>
          <p className='text-md'>Fancare would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
          <ul className='list-disc list-inside '>
              <li><b>The right to access -</b> You have the right to request Fancare for copies of your personal data. We may charge you a small fee for this service.</li>
              <li><b>The right to rectification -</b> You have the right to request that Fancare correct any information you believe is inaccurate. You also have the right to request Fancare to complete the information you believe is incomplete.</li>
              <li><b>The right to erasure -</b> You have the right to request that Fancare erase your personal data, under certain conditions. Please contact Fancare using the email: fancareapp@gmail.com </li>
              <li><b>The right to restrict processing -</b> You have the right to request that Fancare restrict the processing of your personal data, under certain conditions.</li>
              <li><b>The right to object to processing -</b> You have the right to object to Fancare's processing of your personal data, under certain conditions.</li>
              <li><b>The right to data portability -</b> You have the right to request that Fancare transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
            </ul>
          <p className='text-md'>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: fancareapp@gmail.com</p>

          <br />
          <h2 className=' text-xl font-bold'>Privacy policies of other websites</h2>
          <p className='text-md'>The Fancare website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>
          
          <br />
          <h2 className=' text-xl font-bold'>Changes to our privacy policy</h2>
          <p className='text-md'>Fancare keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 11 October 2023</p>

          <br />
          <h2 className=' text-xl font-bold'>How to contact us</h2>
          <p className='text-md'>If you have any questions about Fancare‘s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</p>
          <p className='text-md'>Email us at: fancareapp@gmail.com</p>


      </div>
    </section>
    )
}

export default Privacy