import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from '../../../components/Modal/Modal'
import DonatePayment from './DonatePayment'
import { getUserData } from '../../../helpers/Auth'

const Donate = ({user, currency, currencyData}) => {
    const [showModalCheckout, setShowModalCheckout] = useState(false)
    const [userData, setUserData] = useState(getUserData)
    const [giftSender, setGiftSender] = useState(userData?.username ||"Anonymous")
    const [giftMessage, setGiftMessage] = useState("")
    const [price, setPrice] = useState(0)

    const handleCheckout = async () => {
      await fetch('https://api.fancareapp.com/checkout-donate' , {method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
       body: JSON.stringify({username: user?.name,  amount: price, currency: "usd", fanname: userData?.username, fanAlias: giftSender, message: giftMessage})})
       .then((response) => {
        console.log(response)
        return response.json();
       }).then((response) => {
        
        if(JSON.parse(response)["url"]){
          window.location = JSON.parse(response)["url"];
        }
       })
  }
  
  
    return (
      <div className="p-4 flex flex-col m-auto">

          <div className="flex-1 my-4 ">
            <p className="text-lg text-colorLightGray">Thank you for donating to {user?.name}!</p>
            <p className="text-lg text-colorLightGray ">I am sure they will appreciate it 😌</p>
          </div>
          <div className="mb-4">
            <label className="block text-md mb-2" for="message">
                Amount (USD)
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline mb-4" id="price" name="price" type="number"  min="0.00"  step="0.01"  required value={price} onChange={(e) => setPrice(e.target.value)} />
            <p className="text-colorLightGray">{user?.name} receives: USD {Math.round((price) * 100) / 100}</p>

            <p className="text-colorLightGray">You pay: USD {Math.round((price * 1.1) * 100) / 100}</p>
            {currency !== "USD" &&  <p className="text-colorLightGray">{currency !== "USD" && <i>est.</i> }  {currency} {(Math.round((price )  * 1.1 *   currencyData[currency] *  100) / 100)}</p>}

            </div>
          <div className="my-4 ">
              <label className="block text-md mb-2" for="name">
                  From:
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="name" name="name" type="name" required value={giftSender} onChange={(e) => setGiftSender(e.target.value)} />
            </div>
          <div className="mb-4 ">
              <label className="block text-md mb-2" for="name">
                  Optional Message
              </label>
              <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="name" name="name" type="name" required value={giftMessage} onChange={(e) => setGiftMessage(e.target.value)} />
            </div>


           <button className=' bg-colorPrimary focus:outline-none font-medium px-6 py-2.5  my-6 text-colorLight rounded-full' onClick={() => handleCheckout()}>Donate</button>
          
           <Modal setShowModal={setShowModalCheckout} showModal={showModalCheckout} title={<p>Donate to {user?.name} </p>} content={<DonatePayment  username={user?.name}  amount={price} currency={"usd"} fanname={userData?.username} fanAlias={giftSender} message={giftMessage}/>}/>
        

        </div>
    )
  }

export default Donate