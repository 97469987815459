import React from 'react'
import { Helmet } from 'react-helmet-async';

import FAQ from '../../components/FAQ/Faq';
import wishlist from '../../img/wishlist.png'

const NicheWishlist = ({niche}) => {
  return (
    <>
        <Helmet>
        <title>Wishlist for {niche} creators | Fancare - Privacy-first Wishlist for Influencers and Content Creators</title>
        <meta name="description" content={`A privacy-first wishlist platform specially created for ${niche} creators. Create an account now and start receiving gifts from your fans!`}/>
        <link rel="canonical" href={`/${niche}-wishlist`}/>
      </Helmet>

      <section className= "flex flex-col items-center max-w-screen-xl px-4 py-16 mx-auto lg:pt-40 lg:pb-20 ">

        <div className='text-center sm:w-50vw'>
          <h1 className='text-6xl text-colorDarkGray font-bold'>Best wishlist for <u className='decoration-colorSecondary'>{niche}</u> creators.</h1> 

          <h2 className='text-xl pt-8 pb-16 text-colorLightGray font-medium'>{`A privacy-first wishlist platform specially created for ${niche} creators. `}</h2>
          


        </div>

      </section>



      <section id="features" className="w-screen-xl flex flex-col items-center  px-4 py-16  lg:py-40 bg-colorPrimaryDark text-colorBg">

<div className='text-center sm:w-50vw'>
  <h2 className='text-xl uppercase font-medium pb-8 text-colorDarkGray'>{niche} Monetization </h2>

  

  <h3 className='text-6xl  font-bold '>Receive Cash for Your Gifts</h3>

  <div className='flex justify-center -mt-8 pb-8'>
    <svg xmlns="http://www.w3.org/2000/svg" className='w-20' viewBox="0 0 300 300"><path fill="#f59e0b" d="M78.3 189.56L20.88 141.5 40.14 118.5 78.3 150.44 126.1 110.44 173.89 150.44 221.69 110.44 279.12 158.5 259.86 181.5 221.69 149.56 173.89 189.56 126.1 149.56 78.3 189.56z"></path></svg>
  </div>

  <p className='opacity-70 text-lg'>At Fancare, we empower {niche} creators to turn their passion into profit. </p>
  <br />
  <a className=' text-lg' href="#how-it-works">How it works.</a>
<div className='flex items-center justify-center m-0 md:m-16  p-16'>

    <img src={wishlist} alt="" className=' w-full  rounded-3xl'/>

  </div>
</div>
 

</section>

      <section id="how-it-works" className= "flex flex-col items-center max-w-screen-xl px-4 py-16 mx-auto lg:py-40 text-colorDarkGray">
    <div className=' sm:w-50vw'>

      <h1 className='text-center text-6xl  font-bold pb-16'>4 <u className='decoration-colorSecondary'>Simple</u> Steps.</h1>

      <div className= "grid max-w-screen-xl  mx-auto  lg:grid-cols-12">
      <div className='mr-auto place-self-center flex flex-col gap-10  lg:col-span-7 text-xl'>
        <div className='flex'>
          <span>1.</span>
          <p><b>Create a Fancare account and verify your email.</b></p>
        </div>  

        <div className='flex'>
          <span>2.</span>
          <p><b>Set up payments using Stripe via your Fancare Dashboard.</b> You can now receive donations!</p>
        </div>  

        
        <div className='flex'>
          <span>3.</span>
          <p><b>Add gifts to your wishlist.</b> This can be anything you like, simply set a price and add a description. </p>
        </div>  


       <div className='flex'>
          <span>4.</span>
          <p><b>Let your fans know about your Fancare account! </b>You will be notified when you receive a gift, and your cash will be deposited into your account.</p>
        </div>  


      </div>
 

      </div>

    </div>
  

  </section>
      <FAQ/>
    </>
  )
}

export default NicheWishlist