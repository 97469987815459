import React from "react";
import { RiCloseCircleFill } from "react-icons/ri"

const Modal = ({showModal, setShowModal, content, title}) => {
  return (
    <>
      {showModal ? (
        
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-colorPrimary bg-opacity-50 ">
            
            <div className="relative max-h-80vh w-auto my-6 mx-auto max-w-3xl ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                <div className="flex justify-between p-6 border-b border-solid border-gray-300 rounded-t gap-8 ">
                  <h3 className="text-3xl ">{title}</h3>
                  <button onClick={() => setShowModal(false)} > <RiCloseCircleFill className="text-2xl"/></button>
                </div>
                <div className="relative p-6 flex-auto">
                  {content}
                </div>
              </div>
            </div>
          </div>
        
      ) : null}
    </>
  );
};

export default Modal;