import React, {useState, useEffect} from 'react'

const Purchases = ({username, currency, currencyData}) => {
    const [purchases, setPurchases] = useState();

    let options = {  
        year: "numeric", month: "short",  
        day: "numeric", hour: "2-digit", minute: "2-digit"  
    }; 

    useEffect(() => {
      const token = localStorage.getItem('token');

        fetch('https://api.fancareapp.com/purchases/' + username, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token }})
        .then(res => res.json())
        .then(purchases => {
          setPurchases(purchases.reverse())})
  
    },[]);
  
  return (
    <div className='py-10'>
        {currencyData &&  purchases?.map(p => {
        let date = new Date(p.createdAt);
        return <div className='flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-8 p-4  w-full border'>
        <img
            src={p.imageUrl}
            alt={p.name}
            className="w-20 h-auto rounded-l"
        />
        <div className='w-auto md:w-20vw'>
        <h2 className='text-lg font-bold'>{p.itemName} </h2>
        <h2 className='text-md '>To {p.username}</h2>
        <p className='font-bold'>{currency !== "USD" && <i>est.</i> }  {currency} {(Math.round((p.price + Number.EPSILON) * currencyData[currency]  * 1.1 *  100) / 100) }</p>
        </div>
        <div>

        </div>
        <div className='w-auto md:w-20vw'>
          <p>Message :</p>
          <p><i>{p.message}</i></p>
        </div>

        <p>{date.toLocaleTimeString("en-uk", options)}</p>
    </div>})}
    </div>
  )
}

export default Purchases