import React, {useState } from 'react'
import { getUserData } from '../../helpers/Auth';

const ChangePassword = ({setShowModal}) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setnewPassword] = useState('');
    const [newPassword2, setnewPassword2] = useState('');
    const [error, setError] = useState('');
    const [passwordType, setPasswordType] = useState('password');

    const user = getUserData()
    const name = user.username

    const togglePassword = () => {
        if(passwordType === 'password'){
          setPasswordType('text') 
        }else{
          setPasswordType('password')
        }
      }

    const handleReset = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        if(newPassword.length < 8 ){
          setError("Passwords must be at least 8 characters")
          return
        }
        if(newPassword === newPassword2){
            fetch('https://api.fancareapp.com/new-password/' + name, {                
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token 
                },
                    body: JSON.stringify({ name, currentPassword, newPassword })
        
                }).then(res => 
                    {if (res.ok){
                        setShowModal(false)

                    }else{
                        setError("Incorrect password")
                    }

                    })
        }else{
            setError("Passwords do not match")
        }

    };

  return (
    <section className='flex justify-center'>
    <form className="text-left " onSubmit={handleReset}>
    <br />
    <p className='text-red-500'>{error}</p>
    <br />
    <div className="mb-4">
    <label className="block text-md mb-2" for="password">
        Current Password
    </label>
    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="password" name="password" type="password" required  onChange={(e) => setCurrentPassword(e.target.value)} />
    </div>
    <div className="mb-4">
    <label className="block text-md mb-2" for="password">
        New Password
    </label>
    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline" id="newpassword" name="password" type={passwordType} required  onChange={(e) => setnewPassword(e.target.value)} />
    </div>
    <div className="mb-4">
    <label className="block text-md mb-2" for="password">
        Retype New Password
    </label>
    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-colorLightGray leading-tight focus:outline-none focus:shadow-outline"  id="newpassword2" name="password" type={passwordType} required  onChange={(e) => setnewPassword2(e.target.value)} />
    </div>
    <div className="mb-4 flex gap-4">
      <input type="checkbox" onClick={togglePassword}/>
      <label className="block text-md mb-2" for="password">
          Show passwords
      </label>
      </div>

    <div className="flex  justify-between">
    <button className=' bg-colorPrimary focus:outline-none font-medium px-6 py-2.5  my-6 text-colorLight rounded-full' type="submit" >
        Reset Password
    </button>
    </div>

    </form>
    </section>
  )
}

export default ChangePassword