import React, {useState, useEffect} from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Checkout from './Checkout';

const stripePromise = loadStripe("pk_live_51NIu3BIZLxYYDtsFjevjGpnCWtuwKsz4GUlZVmlOMxdNaBqn42yvNj9J94O8EY8Fp9yfiamJzTat1vaUKQnflWAx00pdvmdfKy");

const StripePayment = ({username, itemId, currency, fanname, fanAlias, message}) => {
    const [clientSecret, setClientSecret] = useState()

    const handleCheckout = async () => {
        const res = await fetch('https://api.fancareapp.com/create-payment-intent' , {method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
         body: JSON.stringify({username,  itemId, currency, fanname, fanAlias, message})});
    
        const { clientSecret} = await res.json();  
        console.log(clientSecret)
        setClientSecret(clientSecret)
    }

    useEffect( ()  => {

        handleCheckout()
    },[])

  return (
    <>

    {clientSecret && 
    <Elements stripe={stripePromise} options={{clientSecret}}>
    <Checkout username={username}/>
    </Elements>
    }
        </>
  )
}

export default StripePayment