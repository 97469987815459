import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { getUserData, isAuthenticated } from '../../helpers/Auth';

import Modal from '../../components/Modal/Modal';
import NewEmail from '../Dashboard/NewEmail';
import NewPassword from '../Dashboard/ChangePassword';
import Purchases from '../Dashboard/Purchases';
import GiftsReceived from '../Dashboard/GiftsReceived';
import Donations from '../Dashboard/Donations';

import UpdateDetails from '../Dashboard/UpdateDetails';
import { Helmet } from 'react-helmet-async';

const Dashboard = ({currency, currencyData}) => {
  const [userData, setUserData] = useState(getUserData)
  const [user, setUser] = useState()
  const [edited, setEdited] = useState(false)
  const [onboarded, setOnboarded] = useState(true)
  const { tab } = useParams();

  const [showModalEmail, setShowModalEmail] = useState(false)
  const [showModalPass, setShowModalPass] = useState(false)

  const [selected, setSelected] = useState(tab || "home")


  useEffect(() => {
      fetch('https://api.fancareapp.com/user/' + userData?.username)
      .then(res => res.json())
      .then(user => {
        if(user?.stripeId){

          fetch('https://api.fancareapp.com/check_onboarded/'+ user.name + '/' , {headers: {
            'Content-Type': 'application/json',
          }}).then(
            res => res.json())
            .then(onboarded => setOnboarded(onboarded))
        }else{
          setOnboarded(false)
        }
        setUser(user)})
  },[ edited]);

  const removeNotification = (id) => {
    const token = localStorage.getItem('token');

    fetch('https://api.fancareapp.com/removeNotification/' +  userData?.username,  {method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token },
     body: JSON.stringify({name : user?.name, id })}).then(() => {
      window.location.reload()})
   
  }
  

  const stripeIntegration = () => {
    const token = localStorage.getItem('token');

    fetch('https://api.fancareapp.com/stripe-integration/' + userData?.username,  {method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
     body: JSON.stringify({name : user?.name})})
      .then(res => res.json())
      .then(data => window.location.assign(data.url))
  }



  return (
    <>
    <Helmet>
      <title>Fancare Dashboard | Privacy-first Wishlist for Creators</title>
      <meta name="description" content="Login to view your Fancare Dashboard"/>
      <link rel="canonical" href="/dashboard"/>
    </Helmet>
    
     <div className='w-full  bg-colorLightGray shadow py-8 px-16  flex  items-center justify-end m-0 '>
      </div>
    <section className='min-h-screen flex flex-col md:flex-row  '>

      {isAuthenticated() ? 
      <>

     


      <aside className="bg-colorPrimaryDark text-white md:w-64 h-fill w-full p-4 border-b border-colorDarkGray ">
        <nav className="">
          <ul className="space-y-2">
           <button onClick={() => setSelected("home")}>
            <li className="">
                <div className="flex items-center justify-between p-2 hover:opacity-80">
                  <div className="flex items-center">
                    <p>Home</p>
                  </div>
                  <i className="fas fa-chevron-down text-xs"></i>
                </div>
              </li>
            </button>

  
             {!onboarded && user?.emailVerified ? 
              <li className="">
                <div className="flex items-center justify-between p-2 hover:opacity-80">
                  <div className="flex items-center">
                 <button ><p  onClick={stripeIntegration}>Stripe Integration</p></button>
                  </div>
                  <i className="fas fa-chevron-down text-xs"></i>
                </div>
              </li>
             : 
             <li className="">
             <div className="flex items-center justify-between p-2 hover:opacity-80">
               <div className="flex items-center">
              <a href={"https://connect.stripe.com/express_login"}> <p  >Stripe Dashboard</p></a>
               </div>
               <i className="fas fa-chevron-down text-xs"></i>
             </div>
           </li>
           }


            <li className="">
            <button onClick={() => setSelected("customize")}>
              <div className="flex items-center justify-between p-2 hover:opacity-80">
                <div className="flex items-center">
                  <p>Customize</p>
                </div>
                <i className="fas fa-chevron-down text-xs"></i>
              </div>
            </button>
            </li>
            <li className="">
            <button onClick={() => setSelected("purchases")}>
              <div className="flex items-center justify-between p-2 hover:opacity-80">
                <div className="flex items-center">
                  <p>Purchases</p>
                </div>
                <i className="fas fa-chevron-down text-xs"></i>
              </div>
            </button>
            </li>
            <li className="">
            <button onClick={() => setSelected("gifts")}>
              <div className="flex items-center justify-between p-2 hover:opacity-80">
                <div className="flex items-center">
                  <p>Gifts Received</p>
                </div>
                <i className="fas fa-chevron-down text-xs"></i>
              </div>
            </button>
            </li>
            <li className="">
            <button onClick={() => setSelected("donations")}>
              <div className="flex items-center justify-between p-2 hover:opacity-80">
                <div className="flex items-center">
                  <p>Donations Received</p>
                </div>
                <i className="fas fa-chevron-down text-xs"></i>
              </div>
            </button>
            </li>
            
            <li className="">
            <button onClick={() => setShowModalEmail(true)}>
              <div className="flex items-center justify-between p-2 hover:opacity-80">
                <div className="flex items-center">
                <p  >Reset Email</p>
                </div>
                <i className="fas fa-chevron-down text-xs"></i>
              </div>
              </button>
            </li>
            <li className="">
            <button onClick={() => setShowModalPass(true)}>
              <div className="flex items-center justify-between p-2 hover:opacity-80">
                <div className="flex items-center">
               <p  >Reset Password</p>
                </div>
                <i className="fas fa-chevron-down text-xs"></i>
              </div>
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      <main className="w-full mx-auto p-4">
        {selected === "home" && 
          <>          
            <h1  className='text-xl text-colorDarkGray font-bold mt-8'>Hi {user?.displayName}, Welcome to your dashboard!</h1>
            <div className='bg-white rounded-lg shadow p-4 flex flex-col gap-4 items-start mt-8'> 
            {user?.notifications.map((notification,i) => <p className='font-bold'>{notification} <button onClick={() => removeNotification(i)} className='text-colorPrimaryDark hover:text-colorPrimary'>Got it!</button></p>)
            }
            {!user?.notifications.length && <p className='font-bold'>No notifications yet.</p>}        
            </div>
            <div className='bg-white rounded-lg shadow p-4 flex flex-col gap-4 items-start mt-8'> 
            <p className="font-bold">Use the tabs on the left to update and view various details relating to your Fancare account.</p>

            
            {user && !user?.emailVerified && 
            <div className='w-full  bg-red-500 shadow py-8 px-16  flex flex-col  items-center justify-center m-0'>
              <p className='text-white text-md'>You must verify your email before you setting up your wishlist!</p>
            </div>}
                        
            </div>
          </>
        }

        {selected === "customize" && 
          <>          
            <div className='bg-white rounded-lg shadow p-4 '> 
            <h1 className="font-bold text-lg">Customize your Profile</h1>

            <UpdateDetails/>
            </div>
          </>
        }

        {selected === "purchases" && 
          <>          
            <div className='bg-white rounded-lg shadow p-4 '> 
            <h1 className="font-bold text-lg">Purchases</h1>

            <Purchases username={userData?.username} currency={currency} currencyData={currencyData}/>
            </div>
          </>
        }

        
        {selected === "gifts" && 
          <>          
            <div className='bg-white rounded-lg shadow p-4 '> 
            <h1 className="font-bold text-lg">Purchases</h1>

            <GiftsReceived username={userData?.username} currency={currency} currencyData={currencyData}/>
            </div>
          </>
        }

        {selected === "donations" && 
          <>          
            <div className='bg-white rounded-lg shadow p-4 '> 
            <h1 className="font-bold text-lg">Donations</h1>

            <Donations username={userData?.username} currency={currency} currencyData={currencyData}/>
            </div>
          </>
        }
     
     



          
        <Modal setShowModal={setShowModalEmail} showModal={showModalEmail} content={<NewEmail setShowModal={setShowModalEmail}/>} title={"Reset Email"}/>
        <Modal setShowModal={setShowModalPass} showModal={showModalPass} content={<NewPassword setShowModal={setShowModalPass}/>} title={"Reset Password"}/>


      </main>
      
    </>
     : 
     <>
      Log in to view your dashboard
     </> }
    </section>


    </>

    
  )
}

export default Dashboard