import React, {useEffect, useState} from 'react'
import {AiOutlineTrophy} from 'react-icons/ai'
import {BiTime, BiDollarCircle} from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { timeAgo } from '../../../helpers/Time'

import display from '../../../img/defaultUser.png'

const Stats = ({username, currency, currencyData}) => {
    const [recentGifters, setRecentGifters] = useState()
    const [topGifters, setTopGifters] = useState()
    const [topDonations, setTopDonations] = useState()

    useEffect(() =>{
        fetch('https://api.fancareapp.com/getRecentGifters/' + username )
        .then(res => res.json())
        .then(users => {
            setRecentGifters(users.reverse())})

        fetch('https://api.fancareapp.com/getTopGifters/' + username )
        .then(res => res.json())
        .then(users => {
            setTopGifters(users)})
        
        fetch('https://api.fancareapp.com/getTopDonations/' + username )
        .then(res => res.json())
        .then(users => {
            setTopDonations(users)})
        }, [username])
    
  return (
    <section className='py-16 '>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
            <div  className="bg-white rounded-lg shadow p-4 flex flex-col ">
                <h2 className="text-lg flex items-center text-colorGray  font-bold"><AiOutlineTrophy/>{username}'s Top Gifters</h2>
                <div className='overflow-y-auto overflow-x-hidden	 h-50vh bg-colorBg  my-4 rounded-lg border' >
                    {topGifters?.map((gifter, i) => (
                        <>
                    
                        {gifter.anon === true ? 
                            <div  className={i%2-1 ? 'flex items-center gap-4 p-4  w-full ' : 'flex items-center  gap-4 w-full p-4 bg-white'}>
                                <img src={gifter.userData[0]?.profilePictureUrl ? gifter.userData[0].profilePictureUrl : display} alt="" className='rounded-full  h-10 shadow ' />
                                
                                <h3 className='font-bold text-gray-500'>{i+1}. {gifter._id}</h3>
                                <p>{currency !== "USD" && <i>est.</i> }  {currency} {(Math.round((gifter.totalGifted + Number.EPSILON) * currencyData[currency] * 100) / 100) }</p>
                            </div>
                        :
                        <Link to={"/" + gifter._id}>
                             <div  className={i%2-1 ? 'flex items-center  gap-4 p-4  w-full ' : 'flex items-center gap-4  w-full p-4 bg-white'}>
                                <img src={gifter.userData[0]?.profilePictureUrl ? gifter.userData[0].profilePictureUrl : display} alt="" className='rounded-full  h-10 shadow ' />
                                <h3 className='font-bold'>{i+1}. {gifter._id}</h3>
                                <p>{currency !== "USD" && <i>est.</i> }  {currency} {(Math.round((gifter.totalGifted + Number.EPSILON) * currencyData[currency] * 100) / 100)}</p>
                            </div>
                        </Link>
                        }
    
                        </>

                    ))}
                </div>
            </div>
            <div  className="bg-white rounded-lg shadow p-4 flex flex-col">
                <h2 className="text-lg flex items-center text-colorGray font-bold"><BiTime/>{username}'s Recent Gifters</h2>
                <div className='overflow-y-auto overflow-x-hidden	 h-50vh bg-colorBg  my-4 rounded-lg border' >
                    {recentGifters?.map((gifter,i) => (

                                <>
                                                    
                                {gifter.anon === true ? 
                                    <div  className={i%2-1 ? 'flex items-center gap-4 p-4 w-full ' : 'flex items-center  gap-4  w-full p-4 bg-white'}>
                                       <img src={gifter.userData[0]?.profilePictureUrl ? gifter.userData[0].profilePictureUrl : display} alt="" className='rounded-full  h-10 shadow ' />
                                        <h3 className='font-bold text-gray-500'>{gifter.fanAlias}</h3>
                                        <p>{timeAgo(new Date (gifter.createdAt))}</p>
                                    </div>
                                :
                                <Link to={"/" + gifter.fanAlias}>
                                   <div  className={i%2-1 ? 'flex items-center gap-4 p-4  w-full ' : 'flex items-center  gap-4 w-full p-4 bg-white'}>
                                        <img src={gifter.userData[0]?.profilePictureUrl ? gifter.userData[0].profilePictureUrl : display} alt="" className='rounded-full  h-10 shadow ' />
                                        <h3 className='font-bold '>{gifter.fanAlias}</h3>
                                        <p>{timeAgo(new Date (gifter.createdAt))}</p>
                                    </div>
                                </Link>
                                }

                                </>


                    ))}
                </div>

            </div>
            <div  className="bg-white rounded-lg shadow p-4 flex flex-col">
                <h2 className="text-lg flex items-center text-colorGray font-bold"><BiDollarCircle/>{username}'s Top Donations</h2>
                <div className='overflow-y-auto overflow-x-hidden	 h-50vh bg-colorBg  my-4 rounded-lg border' >
                    {topDonations?.map((gifter,i) => (
                                <>
                                
                                {gifter.anon === true ? 
                            <div  className={i%2-1 ? 'flex items-center gap-4 p-4 w-full ' : 'flex items-center  gap-4  w-full p-4 bg-white'}>
                            <img src={gifter.userData[0]?.profilePictureUrl ? gifter.userData[0].profilePictureUrl : display} alt="" className='rounded-full  h-10 shadow ' />
                                        <h3 className='font-bold text-gray-500'>{i+1}. {gifter.fanAlias}</h3>
                                        <p>{currency !== "USD" && <i>est.</i> }  {currency} {(Math.round((gifter.amount + Number.EPSILON) * currencyData[currency] * 100) / 100) }</p>
                                    </div>
                                :
                                <Link to={"/" + gifter.fanAlias}>
                            <div  className={i%2-1 ? 'flex items-center gap-4 p-4  w-full ' : 'flex items-center  gap-4 w-full p-4 bg-white'}>
                                        <img src={gifter.userData[0]?.profilePictureUrl ? gifter.userData[0].profilePictureUrl : display} alt="" className='rounded-full  h-10 shadow ' />
                                        <h3 className='font-bold'>{i+1}. {gifter.fanAlias}</h3>
                                        <p>{currency !== "USD" && <i>est.</i> }  {currency} {(Math.round((gifter.amount + Number.EPSILON) * currencyData[currency] * 100) / 100) }</p>
                                    </div>
                                </Link>
                                }

                                </>
   

                    ))}
                </div>

            </div>
        </div>
    </section>

  )
}

export default Stats