import React, {useState, useEffect} from 'react'
import {FaGift, FaHandHoldingHeart, FaDonate} from 'react-icons/fa'



const Achievements = ({username}) => {
  const [achievements, setAchievements] = useState()
  const [userAchieved, setUserAchieved] = useState()

  useEffect(() => {
    fetch('https://api.fancareapp.com/achievements').then((res) => res.json()).then((achievements) => {
      console.log(achievements.giftsReceived)
      setAchievements(achievements)})
  },[])

  useEffect(() => {
    fetch('https://api.fancareapp.com/achievements/' + username).then((res) => res.json()).then((achievements) => {
      console.log(achievements)
      setUserAchieved(achievements)})
  },[username])

  return (
    
    <section className='py-16 '>
      {//text-blue-500
      }
        <h1 className='text-lg  text-colorGray  font-bold '>{username}'s Achievements</h1>
        <br />
        <div className='flex flex-col gap-8 '>

          <div className={`grid grid-cols-1 sm:grid-cols-6 gap-4`}>
            
            {achievements?.giftsReceived?.map((achievement) => 
            

              <div className={userAchieved?.includes(achievement.id)?'bg-white rounded-lg text-center items-center shadow gap-2 p-4 pt-8 flex flex-col ':'bg-white opacity-50 rounded-lg text-center items-center shadow gap-2 p-4 pt-8 flex flex-col '}>
                <FaGift className={`text-3xl mb-2 text-${achievement.iconColor}-500`}/>
                <h1 className=' font-bold'>{achievement.title}</h1>
                <p className='text-colorLightGray'>{achievement.description}</p>
                {userAchieved?.includes(achievement.id) && <p className='text-colorPrimaryDark font-bold'>Completed!</p>
                }
              </div>
             
            
            )}


          </div>

          <div className='grid grid-cols-1 sm:grid-cols-6 gap-4'>

            {achievements?.giftsGiven?.map((achievement) => 
              
              <div className={userAchieved?.includes(achievement.id)?'bg-white rounded-lg text-center items-center justify-between shadow gap-2 p-4 pt-8 flex flex-col ':'bg-white opacity-50 rounded-lg text-center items-center justify-between shadow gap-2 p-4 pt-8 flex flex-col '}>
                <FaHandHoldingHeart className={`text-3xl mb-2 text-${achievement.iconColor}-500`}/>
                <h1 className=' font-bold'>{achievement.title}</h1>
                <p className='text-colorLightGray'>{achievement.description}</p>
                {userAchieved?.includes(achievement.id) && <p className='text-colorPrimaryDark font-bold'>Completed!</p>
                }
              </div>
            )}
          </div>

          <div className='grid grid-cols-1 sm:grid-cols-6 gap-4'>

          {achievements?.donationsReceived?.map((achievement) => 
            
            <div className={userAchieved?.includes(achievement.id)?'bg-white rounded-lg text-center items-center justify-between shadow gap-2 p-4 pt-8 flex flex-col ':'bg-white opacity-50 rounded-lg text-center items-center justify-between shadow gap-2 p-4 pt-8 flex flex-col '}>
              <FaDonate className={`text-3xl mb-2 text-${achievement.iconColor}-500`}/>
              <h1 className=' font-bold'>{achievement.title}</h1>
              <p className='text-colorLightGray'>{achievement.description}</p>
              {userAchieved?.includes(achievement.id) ? <p className='text-colorPrimaryDark font-bold'>Completed!</p>:<p></p>
              }
            </div>
          )}
          </div>

          </div>



    </section>
   )
}

export default Achievements